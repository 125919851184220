import {Axios, getUserLS} from '@asocial/fe-utils'
import {createSelector, createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {mediaVariants, preloadImages} from 'shared/lib'
import {sentryTrackError} from 'shared/lib/sentry'
import {CursorType, ErrorResponsesType, Me, SearchUser} from 'shared/types'
import {findMeItems, FindMeKeys, SEARCH_LIMIT, tabItems, TabKeys} from './config'

const getDefaultFindMe = () => {
  const user = getUserLS() as Me
  if (!user) return findMeItems.female.id

  return user.partner === null ? findMeItems.anyone.id : findMeItems.female.id
}

export type searchPeopleFiltersType = {
  tab: TabKeys
  findMe: FindMeKeys
  ageFrom: string
  ageTo: string
  continents: string
}

export type SearchPeopleModelProps = {
  cursor: CursorType
  list: SearchUser[]
  filters: searchPeopleFiltersType
  isLoading: boolean
}

export const initialState: SearchPeopleModelProps = {
  cursor: '',
  list: [],
  filters: {
    tab: tabItems.all.id,
    findMe: getDefaultFindMe(),
    ageFrom: '',
    ageTo: '',
    continents: ''
  },
  isLoading: false
}

export const searchPeopleModel = createSlice({
  name: 'searchPeople',
  initialState,
  reducers: {
    setFilter: (state, {payload}: PayloadAction<{key: keyof searchPeopleFiltersType; value: any}>) => {
      state.cursor = ''
      state.list = []

      state.filters[payload.key] = payload.value as never
    },
    setSearchFilters: (state, {payload}: PayloadAction<searchPeopleFiltersType>) => {
      state.cursor = ''
      state.list = []

      state.filters = payload
    },
    resetFilters: (state) => {
      state.cursor = ''
      state.list = []

      state.filters.ageFrom = ''
      state.filters.ageTo = ''
      state.filters.continents = ''
    },
    addSearchPeopleList: (state, {payload}: PayloadAction<{cursor: CursorType; data: SearchUser[]}>) => {
      state.cursor = payload.cursor
      state.list.push(...payload.data)
    },
    updateLikeSearch: (state, {payload}: PayloadAction<{idUser: string; value: boolean}>) => {
      const item = state.list.find((item) => item.id === payload.idUser)
      if (!item) return

      item.isLiked = payload.value
    },
    setSearchLoading: (state, {payload}: PayloadAction<boolean>) => {
      state.isLoading = payload
    }
  }
})

export const {setFilter, setSearchFilters, resetFilters, addSearchPeopleList, updateLikeSearch, setSearchLoading} =
  searchPeopleModel.actions

type SearchPeopleResponse = AxiosResponse<{
  cursor: CursorType
  data: SearchUser[]
}>

export const GetSearchPeople = (dispatch: Dispatch) =>
  useQuery<SearchPeopleResponse, ErrorResponsesType, SearchPeopleResponse, [string, SearchPeopleModelProps]>({
    queryKey: ['searchPeople', useSelector((state: RootState) => state.searchPeople)],
    queryFn: ({
      queryKey: [
        _,
        {
          cursor,
          filters: {continents, ageFrom, ageTo, tab, findMe}
        }
      ]
    }) =>
      Axios.get(`search/${tab}`, {
        params: {
          limit: SEARCH_LIMIT,
          cursor: cursor || null,
          continent: continents || null,
          ageFrom: ageFrom || null,
          ageTo: ageTo || null,
          gender: findMe !== findMeItems.anyone.id ? findMe : null
        }
      })
        .then(({data}) => {
          preloadImages(data.data.map((item: SearchUser) => item.avatarUrl + mediaVariants.rectangle512)).catch(
            console.error
          )

          dispatch(addSearchPeopleList(data))
          return data
        })
        .catch((error) => {
          sentryTrackError(`Search people fetching error: ${JSON.stringify(error)}`)
          throw error
        }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })

export const useAllSearchFilters = () => useSelector((state: RootState) => state.searchPeople.filters)

export const useGetSearchCursor = () => useSelector((state: RootState) => state.searchPeople.cursor)

export const useGetSearchPeople = () => useSelector((state: RootState) => state.searchPeople.list)

export const useCountFilters = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.searchPeople.filters.findMe,
      (state: RootState) => state.searchPeople.filters.ageTo,
      (state: RootState) => state.searchPeople.filters.ageFrom,
      (state: RootState) => state.searchPeople.filters.continents,
      (findMe, ageTo, ageFrom, continents) => {
        let countFilters = 0
        if (findMe) countFilters++
        if (ageTo || ageFrom) countFilters++
        if (continents) countFilters++

        return countFilters
      }
    )
  )

export const useIsSearchLoading = () => useSelector((state: RootState) => state.searchPeople.isLoading)
