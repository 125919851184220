import {FC, CSSProperties, ReactNode, forwardRef, ForwardRefRenderFunction, InputHTMLAttributes, useState} from 'react'
import {Button} from 'shared/ui/buttons'
import {Eye, EyeSlash} from 'shared/ui/icons'
import styles from './styles.module.scss'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  containerStyles?: CSSProperties
  children?: ReactNode
  isError?: Boolean
  errorText?: string
  fullwidth?: boolean
  variant?: 'password'
  withoutAutocomplete?: boolean
}

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    containerStyles,
    children,
    errorText,
    isError,
    fullwidth = true,
    variant,
    type,
    autoComplete,
    withoutAutocomplete,
    ...otherProps
  },
  ref
) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <InputContainer label={label} name={name} containerStyles={containerStyles} fullwidth={fullwidth}>
      <div className={`${styles.inputContainer} ${isError ? styles.inputError : ''}`}>
        {errorText ?
          <span className={styles.errorText}>{errorText}</span>
        : null}
        <input
          className={styles.formInput}
          {...otherProps}
          name={name}
          id={name}
          autoComplete={withoutAutocomplete ? undefined : autoComplete || name}
          ref={ref}
          type={
            variant !== 'password' ? type
            : showPassword ?
              'text'
            : 'password'
          }
        />
        {children}
        {variant === 'password' ?
          <Button
            as='unstyled'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowPassword(!showPassword)
            }}
            className={styles.btnPassword}
          >
            {showPassword ?
              <Eye />
            : <EyeSlash />}
          </Button>
        : null}
      </div>
    </InputContainer>
  )
}

export const InputContainer: FC<InputProps> = ({name, label, containerStyles, children, fullwidth = true}) => (
  <div className={styles.container} style={{...containerStyles, width: fullwidth ? '100%' : 'auto'}}>
    <label className={styles.formLabel} htmlFor={name}>
      {' '}
      {label}{' '}
    </label>
    {children}
  </div>
)

export const FormInput = forwardRef(Input)
