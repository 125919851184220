import {Axios} from '@asocial/fe-utils'
import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {CountryKeys} from 'shared/lib'
import {Gender, ChildrenCount, MaritalStatus, Education, InterestCategory, ErrorResponsesType} from 'shared/types'

export type UserType = {
  id: string
  name: string
  gender: Gender
  education: Education
  avatarUrl: string | null
  videoAvatarUrl: string | null
  bannerUrl: string | null
  age: number
  city: string | null
  country: CountryKeys | null
  dateBirth: string
  maritalStatus: MaritalStatus
  childrenCount: ChildrenCount
  occupation: string | null
  about: string | null
  isOnline: boolean
  isPopular: boolean
  isBlocked: boolean
  isConfirmed: boolean
  interests: InterestCategory[]
}

export const initialStateProfileModel: UserType = {
  about: '',
  age: 0,
  avatarUrl: '',
  bannerUrl: '',
  childrenCount: 0,
  city: '',
  country: null,
  dateBirth: '',
  gender: Gender.FEMALE,
  id: '',
  isOnline: false,
  maritalStatus: MaritalStatus.NOT_MARRIED,
  education: Education.NOT_SPECIFIED,
  name: '',
  occupation: '',
  videoAvatarUrl: '',
  isPopular: false,
  isBlocked: false,
  isConfirmed: false,
  interests: []
}

export const profileModel = createSlice({
  name: 'profile',
  initialState: initialStateProfileModel,
  reducers: {
    setProfile: (state, {payload}: PayloadAction<UserType | null>) => {
      if (payload == null) return {...state, isBlocked: true}
      return {...state, ...payload}
    },
    clearProfile: () => initialStateProfileModel
  }
})

export const {setProfile, clearProfile} = profileModel.actions

export const useProfile = () => useSelector((state: RootState) => state.profile)

export const GetUserInfo = (idUser: string, dispatch: Dispatch) =>
  useQuery<AxiosResponse<UserType | null>, ErrorResponsesType>({
    queryKey: ['getUserInfo'],
    queryFn: ({signal}) =>
      Axios.get(`users/${idUser}`, {signal}).then(({data: response}) => {
        dispatch(setProfile(response))
        return response
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })
