import {useCallback, useState} from 'react'
import Turnstile from 'react-turnstile'
import {useTurnstileSiteKey} from 'entities/app'

export const useCaptcha = () => {
  const turnstileSiteKey = useTurnstileSiteKey()
  const [captcha, setCaptcha] = useState('')
  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false)

  const Captcha = useCallback(
    () => (
      <Turnstile
        style={{display: turnstileSiteKey ? 'block' : 'none'}}
        fixedSize
        theme='light'
        sitekey={turnstileSiteKey || '3x00'}
        onVerify={(token) => {
          if (!token) return
          setIsCaptchaPassed(true)
          setCaptcha(token)
        }}
        onError={() => setIsCaptchaPassed(true)}
        onUnsupported={() => setIsCaptchaPassed(true)}
      />
    ),
    [turnstileSiteKey, setIsCaptchaPassed, setCaptcha]
  )

  return {
    Captcha,
    captcha,
    isCaptchaPassed
  }
}
