import {Axios} from '@asocial/fe-utils'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useDispatch} from 'react-redux'
import {ErrorResponsesType, IntroVideo, SearchUser} from 'shared/types'
import {setIntroVideo} from './model'

export const GetUserSimilar = (idUser: string): Promise<AxiosResponse<{data: SearchUser[]}>> =>
  Axios.get(`/users/${idUser}/similar`)

export const PurchaseIntro = (idUser: string): Promise<AxiosResponse<null, ErrorResponsesType>> =>
  Axios.post(`/users/${idUser}/intro`)

export const useGetUserIntro = (idUser: string) => {
  const dispatch = useDispatch()

  return useQuery<IntroVideo, ErrorResponsesType>({
    queryKey: ['getUserIntro', idUser],
    queryFn: ({signal}) =>
      Axios.get(`/users/${idUser}/intro`, {signal})
        .then((response: AxiosResponse<IntroVideo>) => {
          dispatch(setIntroVideo({purchased: response.data.purchased, video: response.data.video}))
          return response.data
        })
        .catch((error) => {
          console.error('Error fetching intro:', error)
          throw error
        }),
    refetchOnWindowFocus: false
  })
}
