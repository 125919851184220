import {Axios} from '@asocial/fe-utils'
import {AxiosResponse} from 'axios'
import {ErrorResponsesType} from 'shared/types'
import {GetNotificationsResponse, NotificationTypes} from './types'

export const GetActivityCountersRequests = (): Promise<
  AxiosResponse<Record<NotificationTypes, number>, ErrorResponsesType>
> => Axios.get('activity/counters')

type SetFavoriteType = {
  isFavorite?: boolean
  idUser: string
}

export const SetFavorite = ({isFavorite, idUser}: SetFavoriteType): Promise<AxiosResponse<null>> =>
  Axios[isFavorite ? 'delete' : 'post'](`activity/favorite/${idUser}`)

type SetProfileLikeParams = {
  isLike?: boolean
  idUser: string
}

export const SetProfileLike = ({isLike, idUser}: SetProfileLikeParams): Promise<AxiosResponse<null>> =>
  Axios[isLike ? 'delete' : 'post'](`activity/like/${idUser}`)

export const GetLikePhotos = (
  idUser: string,
  ids: string[]
): Promise<AxiosResponse<Record<string, boolean>, ErrorResponsesType>> =>
  Axios.post(`/activity/like-photos/${idUser}`, {ids})

export const LikePhoto = (idUser: string, idPhoto: string): Promise<AxiosResponse<null, ErrorResponsesType>> =>
  Axios.post(`/activity/like-photo/${idUser}/${idPhoto}`)

export const UnLikePhoto = (idUser: string, idPhoto: string): Promise<AxiosResponse<null, ErrorResponsesType>> =>
  Axios.delete(`/activity/like-photo/${idUser}/${idPhoto}`)

export const Visit = (idUser: string): Promise<AxiosResponse<null, ErrorResponsesType>> =>
  Axios.post(`/activity/visit/${idUser}`)

export const GetNotificationsRequest = (): Promise<GetNotificationsResponse> => Axios.get('activity/notifications')
