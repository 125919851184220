import {Axios} from '@asocial/fe-utils'
import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {PhotoType} from 'entities/messages'
import {CursorType, ErrorResponsesType} from 'shared/types'

export type MyMediaModelProps = {
  cursor: CursorType
  media: PhotoType[]
}

const initialState: MyMediaModelProps = {
  cursor: '',
  media: []
}

export const myMediaModel = createSlice({
  name: 'myMedia',
  initialState,
  reducers: {
    setMyMedia: (state, {payload}: PayloadAction<PhotoType[]>) => {
      state.media.push(...payload)
    },
    setMyMediaUp: (state, {payload}: PayloadAction<PhotoType[]>) => {
      state.media.unshift(...payload.reverse())
    },
    removeFromMyMedia: (state, {payload}: PayloadAction<{photosIds: string[]}>) => {
      state.media = state.media.filter((photo) => !payload.photosIds.includes(photo.id))
    },
    setMyMediaCursor: (state, {payload}: PayloadAction<CursorType>) => {
      state.cursor = payload
    },
    clearStateMyMedia: () => initialState
  }
})

export const {clearStateMyMedia, setMyMediaUp, removeFromMyMedia} = myMediaModel.actions

type GetMyMediaResponse = AxiosResponse<{
  cursor: string
  data: PhotoType[]
  totalCount: number
}>

export const GetMyMedia = (dispatch: Dispatch) =>
  useQuery<GetMyMediaResponse, ErrorResponsesType, GetMyMediaResponse, [string, CursorType]>({
    queryKey: ['getMyMedia', useSelector((state: RootState) => state.myMedia.cursor)],
    queryFn: ({queryKey: [_, cursor]}) =>
      Axios.get(`/photos`, {
        params: {
          cursor: cursor || null
        }
      }).then(({data}) => {
        dispatch(myMediaModel.actions.setMyMedia(data.data))
        dispatch(myMediaModel.actions.setMyMediaCursor(data.cursor))
        return data
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })

export const useMyMedia = () => useSelector((state: RootState) => state.myMedia.media)

export const useMyMediaCursor = () => useSelector((state: RootState) => state.myMedia.cursor)
