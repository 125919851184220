import {Axios} from '@asocial/fe-utils'
import {Dispatch} from '@reduxjs/toolkit'
import {AxiosResponse} from 'axios'
import {CursorType, ErrorResponsesType} from 'shared/types'
import {GetOrdersRequestType} from './model/orders'
import {addToOrder, deleteToOrder} from './model/realGifts'
import {OrderType, RealGiftType} from './types'

export const AddRealGiftToOrder = (
  idUser: string,
  realGift: RealGiftType,
  dispatch: Dispatch
): Promise<AxiosResponse<null, ErrorResponsesType>> =>
  Axios.post(`/real-gifts/${idUser}/order/${realGift.id}`)
    .then(({data: response}) => {
      dispatch(addToOrder(realGift))
      return response
    })
    .catch(console.error)

export const DeleteRealGiftToOrder = (
  idUser: string,
  idRealGift: number,
  dispatch: Dispatch
): Promise<AxiosResponse<null, ErrorResponsesType>> =>
  Axios.delete(`/real-gifts/${idUser}/order/${idRealGift}`)
    .then(({data: response}) => {
      dispatch(deleteToOrder(idRealGift))
      return response
    })
    .catch(console.error)

export const CreateOrder = (
  idUser: string,
  message: string,
  deliveryDate?: string
): Promise<AxiosResponse<OrderType, ErrorResponsesType>> =>
  Axios.post(`/real-gifts/${idUser}/order`, {message, deliveryDate})

export const CancelOrder = (idOrder: string): Promise<AxiosResponse<OrderType, ErrorResponsesType>> =>
  Axios.post(`/real-gifts/orders/${idOrder}/cancel`)

export const GetOrdersRequest = (cursor: CursorType, signal?: AbortSignal): Promise<GetOrdersRequestType> =>
  Axios.get('/real-gifts/orders', {params: {cursor: cursor || null}, signal})
