import {saveToken, saveUserLS} from '@asocial/fe-utils'
import MenuItem from '@mui/material/MenuItem'
import {SelectChangeEvent} from '@mui/material/Select/Select'
import {TokenResponse, useGoogleLogin} from '@react-oauth/google'
import moment from 'moment/moment'
import {useCallback, useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router'
import {useInitialCountry} from 'entities/app'
import {FormInput, LocationSelect} from 'entities/inputs'
import {FindMeKeys, setFilter} from 'entities/searchPeople'
import {GetGeoUser, GetUserByGoogle, OAuth, RegisterUserResponse, setUser, SexIcon} from 'entities/user'
import {CountryKeys, MonthArray, NUMBER_REGEX} from 'shared/lib'
import {TEXT_ERRORS} from 'shared/lib/constants'
import {GOOGLE_TRACKING_EVENTS, trackGoogleEvent} from 'shared/lib/gtm'
import {ErrorResponsesType, Gender} from 'shared/types'
import {Button} from 'shared/ui/buttons'
import {Flag} from 'shared/ui/icons'
import {AuthModal} from 'shared/ui/modals/authModal'
import {Select} from 'shared/ui/select'
import sharedStyles from 'shared/ui/styles/authModals.module.scss'
import {InputContainerTextField} from 'shared/ui/textField'
//TODO подумать куда вынести
import {useCaptcha} from '../../captcha'
import {GoogleContext, GoogleContextType} from './model'

const createDate = (date: {year: string | number; month: string | number; day: string | number}) =>
  date.year && date.day && date.month ? `${date.year}-${date.month}-${date.day}` : null

type dateKeysType = 'day' | 'month' | 'year'

const dateKeys: dateKeysType[] = ['day', 'month', 'year']

let token = ''

export type GoogleModalErrors = {
  day?: string
  month?: string
  year?: string
  others: string
}

export const GoogleModal = () => {
  const dispatch = useDispatch()
  const [dateBirth, setDateBirth] = useState({day: '', month: '', year: ''})
  const [errors, setErrors] = useState<GoogleModalErrors>({day: '', month: '', year: '', others: ''})
  const [seeking, setSeeking] = useState({[Gender.MALE]: false, [Gender.FEMALE]: false})
  const [gender, setGender] = useState<Gender>(Gender.MALE)
  const [country, setCountry] = useState<CountryKeys>('US')
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const {setGoogleLogin} = useContext(GoogleContext) as GoogleContextType
  const {Captcha, isCaptchaPassed, captcha} = useCaptcha()
  const initialCountry = useInitialCountry()
  const navigate = useNavigate()

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
      token = tokenResponse.access_token
      login().catch(console.error)
    },
    onError: (e) => {
      setIsLoading(false)
      setIsOpen(false)
      console.error('authError', e)
    },
    onNonOAuthError: (e) => {
      setIsLoading(false)
      setIsOpen(false)
      console.error('nonOAuthError', e)
    }
  })

  const login = useCallback(
    async (isNewMan?: boolean) => {
      let oauthData: RegisterUserResponse | null = null

      try {
        setIsLoading(true)

        if (isNewMan) {
          const {data} = await GetUserByGoogle(token)
          const {names} = data

          const oauth = await OAuth(
            {
              idToken: token,
              name: names.find((item) => item.metadata.primary)?.displayName || '',
              dateBirth: createDate(dateBirth),
              gender,
              country,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              turnstile: captcha || null
            },
            'google'
          )

          oauthData = oauth.data

          trackGoogleEvent({
            country: oauthData.user.ipCountry,
            dateBirth: createDate(dateBirth) as string,
            gender,
            event: GOOGLE_TRACKING_EVENTS.SIGN_UP
          })
        }

        if (!isNewMan) {
          const oauth = await OAuth({idToken: token}, 'google')

          oauthData = oauth.data
        }

        if (!oauthData) return

        const {accessToken, expiresIn, refreshToken, user} = oauthData

        dispatch(setUser(user))
        saveUserLS(user)
        saveToken({accessToken, expiresIn, refreshToken})
        // FIXME: По логике мы этого не должны делать так как при закидке в setUser роутинг должен измениться и
        //  дефалт роут должен перекинуть на people без navigate 0 и без всех проблем
        if (!location.pathname.includes('blog')) navigate('/people')

        if (user.partner === null) {
          dispatch(setFilter({value: FindMeKeys.anyone, key: 'findMe'}))
        }

        navigate(0)
      } catch (e) {
        //plug for backend
        const typedError = e as ErrorResponsesType
        console.error('error fetching account data:', e)
        if (typedError.error === 'email_taken') return
        if (typedError.error === 'policy_error')
          setErrors((prevState) => ({...prevState, others: TEXT_ERRORS.INTERNAL_POLICIES}))

        setIsOpen(true)
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, dateBirth, gender, country, captcha]
  )

  const dropErrors = () => setErrors({day: '', month: '', year: '', others: ''})

  const onChangeDate = (e: SelectChangeEvent<unknown>) => {
    if (!NUMBER_REGEX.test(e.target.value as string) && e.target.value !== '') return

    dropErrors()

    setDateBirth((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  const onSubmitHandler = () => {
    if (isLoading) return
    dropErrors()

    //if (emptyFields.gender && !seeking[Gender.MALE] && !seeking[Gender.FEMALE]) return

    if (!dateKeys.every((item) => !!dateBirth[item]))
      return dateKeys.forEach((item) => (!dateBirth[item] ? setErrors((prev) => ({...prev, [item]: ' '})) : null))

    const birthday = moment(`${dateBirth.month}.${dateBirth.day}.${dateBirth.year}`, 'MM.DD.YYYY')

    if (moment().diff(birthday, 'year') < 18)
      return setErrors((prev) => ({...prev, day: 'You must be at least 18 years old', year: ' ', month: ' '}))
    if (birthday.toString() === 'Invalid Date' || moment().diff(birthday, 'year') > 100)
      return setErrors((prev) => ({...prev, day: 'Invalid date', year: ' ', month: ' '}))

    login(true).catch(console.error)
  }

  const onChangeGender = (gender: Gender) => setGender(gender)

  const onChangeSeeking = (gender: Gender) => setSeeking((state) => ({...state, [gender]: !seeking[gender]}))

  useEffect(() => {
    //idk why it's working instead of setGoogleLogin(googleLogin)
    setGoogleLogin(() => googleLogin)
  }, [])

  useEffect(() => {
    if (!isOpen) return

    if (initialCountry) {
      setCountry(initialCountry as CountryKeys)
      return
    }

    GetGeoUser()
      .then(({data}) => setCountry(data.country))
      .catch(console.error)
  }, [isOpen])

  if (!isOpen) return <></>

  return (
    <AuthModal className={sharedStyles.modal} onSubmit={onSubmitHandler}>
      <div className={sharedStyles.modal__titleContainer}>
        <div className={sharedStyles.modal__titleIcon}>
          <Flag />
        </div>
        <div className={sharedStyles.modal__textContainer}>
          <p className={sharedStyles.modal__title}>Details</p>
          <span className={sharedStyles.modal__title__description}>
            Tell us more about yourself and start exploring
          </span>
        </div>
      </div>

      <div className={sharedStyles.modal__body}>
        <div className={sharedStyles.birthdate}>
          <FormInput
            fullwidth
            name='day'
            placeholder='Day'
            label='Birthday'
            type='text'
            maxLength={2}
            size={2}
            value={dateBirth.day}
            onChange={onChangeDate}
            errorText={errors.day}
            isError={Boolean(errors.day)}
          />
          <Select
            placeholder='Month'
            displayEmpty
            name='month'
            fullWidth
            isShowPlaceholderItem={false}
            value={dateBirth.month}
            onChange={onChangeDate}
            sizePopup='xs'
            error={Boolean(errors.month)}
          >
            {MonthArray.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          <FormInput
            fullwidth
            max={new Date().getFullYear()}
            name='year'
            label=''
            placeholder='Year'
            type='text'
            maxLength={4}
            size={4}
            value={dateBirth.year}
            onChange={onChangeDate}
            errorText={errors.year}
            isError={Boolean(errors.year)}
          />
        </div>
        <InputContainerTextField label='Country'>
          <LocationSelect
            sx={{mt: '6px'}}
            placeholder=''
            name='country'
            value={country}
            onChange={(e) => setCountry(e.target.value as CountryKeys)}
          />
        </InputContainerTextField>
        <div className={sharedStyles.sexWrapper}>
          <div className={sharedStyles.sexChoose}>
            <div className={sharedStyles.text}>I am</div>
            <SexIcon
              onClick={() => onChangeGender(Gender.MALE)}
              isActive={gender === Gender.MALE}
              gender={Gender.MALE}
            />
            <SexIcon
              onClick={() => onChangeGender(Gender.FEMALE)}
              isActive={gender === Gender.FEMALE}
              gender={Gender.FEMALE}
            />
          </div>
          <div className={sharedStyles.sexChoose}>
            <div className={sharedStyles.text}>Seeking</div>
            <SexIcon
              onClick={() => onChangeSeeking(Gender.MALE)}
              isActive={seeking[Gender.MALE]}
              gender={Gender.MALE}
            />
            <SexIcon
              onClick={() => onChangeSeeking(Gender.FEMALE)}
              isActive={seeking[Gender.FEMALE]}
              gender={Gender.FEMALE}
            />
          </div>
        </div>
        {errors.others ?
          <p style={{position: 'static'}} className={sharedStyles.error}>
            {errors.others}
          </p>
        : null}
        <Captcha />
        <Button
          isDisabled={isLoading || !isCaptchaPassed}
          isLoading={isLoading}
          type='submit'
          className={sharedStyles.modal__signIn}
          fullwidth
          styleType='primary'
        >
          Continue
        </Button>
      </div>
    </AuthModal>
  )
}
