export enum messageTypes {
  text = 'text',
  photo = 'photo',
  disappearingPhoto = 'disappearing-photo',
  video = 'video',
  disappearingVideo = 'disappearing-video',
  sticker = 'sticker',
  virtualGift = 'virtual-gift',
  audio = 'audio',
  letter = 'letter'
}
