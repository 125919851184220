import {createContext, FC, ReactNode, useState} from 'react'
import {ReactCropperProps} from 'react-cropper'

export type CropperType = ReactCropperProps & {
  isMultiple?: boolean
  isRounded?: boolean
  isOpen: boolean
  images?: string[]
  onClose?: () => void | null
  onSave?: (File: File | File[]) => Promise<void>
}

export type CropperContextType = CropperType & {
  setCropperConfig: (params: CropperType) => void
  openCropper: () => void
  closeCropper: () => void
  setZoom: (value: number) => void
}

export const CropperContext = createContext<CropperContextType | null>(null)

export const CropperProvider: FC<{children: ReactNode}> = ({children}) => {
  const [config, setConfig] = useState<CropperType>({
    isMultiple: false,
    isRounded: false,
    isOpen: false,
    images: [],
    src: '',
    aspectRatio: 1,
    zoomTo: 0.3,
    onClose: undefined,
    onSave: undefined
  })

  const setCropperConfig = (params: CropperType) => {
    setConfig((prevState) => ({...prevState, ...params}))
  }

  const openCropper = () => {
    setConfig((prevState) => ({...prevState, isOpen: true}))
  }
  const closeCropper = () => {
    setConfig((prevState) => ({...prevState, isOpen: false}))
  }

  const setZoom = (value: number) => {
    setConfig((prevState) => ({...prevState, zoomTo: value}))
  }

  return (
    <CropperContext.Provider value={{...config, setCropperConfig, openCropper, closeCropper, setZoom}}>
      {children}
    </CropperContext.Provider>
  )
}
