import {CountryKeys} from 'shared/lib'

export enum PaymentProviderType {
  COREFY = 'corefy',
  COREFY_HPP = 'corefy-hpp',
  COIN_PAYMENTS = 'coin-payments',
  PAYPAL = 'paypal',
  NODA = 'noda'
}

export enum PaymentMethodType {
  CARD = 'card',
  TOKEN = 'token',
  SEPA = 'sepa',
  GOOGLE_PAY = 'google-pay',
  APPLE_PAY = 'apple-pay',
  PAYPAL = 'paypal',
  CRYPTO = 'crypto'
}

export enum CardBrands {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  JCB = 'jcb',
  AMERICAN_EXPRESS = 'american-express',
  DINERS_CLUB = 'diners-club',
  DISCOVER = 'discover',
  ELO = 'elo',
  HIPERCARD = 'hipercard',
  HIPER = 'hiper',
  MAESTRO = 'maestro',
  MIR = 'mir',
  UNIONPAY = 'unionpay'
}

export type PackageType = {
  id: number
  name: string
  credits: number
  stickers: number
  photos: number
  videos: number
  virtualGifts: number
  price: number
  originPrice: number
  autoChargeable: boolean
}

export enum PaymentStatus {
  IN_PROGRESS,
  SUCCESS,
  FAILED,
  SEPA_IN_PROGRESS
}

export type PaymentSuccessResponse = {
  idOrder: string
  package: PackageType
  descriptor?: string
}

export type TokenType = {
  id: string
  brand: CardBrands
  card: string
  expireMonth: string
  expireYear: string
  provider: PaymentProviderType
  dateUnfreeze: string | null
  freezeReason: DeclinePaymentReason | null
}

export type CorefyContextType = {
  country: CountryKeys
  zipCode: boolean
}

export type CryptoOptions = {
  id: string
  name: string
  logoUrl: string
  price: number
}

export type ApplePayContextType = {
  merchant: {
    id: string
    countryCode: string
    currencyCode: string
    supportedNetworks: string[]
    merchantCapabilities: string[]
    total: ApplePayJS.ApplePayLineItem
  }
}

export type Corefy3dsParams = {
  action: string
  method: 'post' | 'put' | 'get'
  params: object | []
} | null

export type CorefyCardContextType = {
  success: boolean
  threeDs: Corefy3dsParams
}

export type PaypalOptions = {
  clientId: string
  currency: string
  merchantId: string
}

export type PaypalOptionsContextType = {merchant: PaypalOptions}

export type PaypalOrderContextType = {id: string}

export enum DeclinePaymentReason {
  THREE_DS_REQUIRED = '3ds_required',
  THREE_DS_FAILED = '3ds_failed',
  TIMED_OUT = 'timed_out',
  PAYMENT_CANCELLED = 'payment_cancelled',
  INSUFFICIENT_BALANCE = 'insufficient_balance',
  TRANSACTION_ERROR = 'transaction_error',
  LIMIT_EXCEEDED = 'limit_exceeded',
  PAYMENT_ERROR = 'payment_error',
  FORBIDDEN_CARD = 'forbidden_card',
  FROZEN_CARD = 'frozen_card',
  CARD_NOT_SUPPORTED = 'card_not_supported',
  NOT_ENOUGH_DATA = 'not_enough_data',
  INVALID_CARD_DATA = 'invalid_card_data',
  INVALID_CARD_NUMBER = 'invalid_card_number',
  INVALID_EXPIRATION_DATE = 'invalid_expiration_date',
  INVALID_CARDHOLDER = 'invalid_cardholder',
  INVALID_CVV = 'invalid_cvv',
  GENERAL_DECLINE = 'general_decline'
}

export type DeclineReasonObject = {
  title: string
  description: string
}

export type PaymentFailedResponse = PaymentSuccessResponse & {
  reason: DeclinePaymentReason
  card: {
    number: string
    holder: string
    year: string
    month: string
  } | null
}
