import {configureStore} from '@reduxjs/toolkit'
import {createReduxEnhancer} from '@sentry/react'
import {favoriteModel, notificationsModel} from 'entities/activity'
import {appModel} from 'entities/app'
import {lettersModel} from 'entities/inbox/model/letters'
import {threadsModel} from 'entities/inbox/model/threads'
import {chatPairModel, dialogsModel, messagesModel, stickersModel, virtualGiftsModel} from 'entities/messages'
import {paymentModel} from 'entities/payment'
import {profileModel, profileMediaModel, myMediaModel, introVideoModel} from 'entities/profile'
import {galleryModel} from 'entities/profile/model/gallery'
import {ordersModel} from 'entities/realGifts/model/orders'
import {realGiftsModel} from 'entities/realGifts/model/realGifts'
import {searchPeopleModel} from 'entities/searchPeople'
import {topicsModel} from 'entities/topics'
import {userModel} from 'entities/user'
import {interestsModel} from 'entities/user/model/interests'
import {datingSafetyPolicyModel} from 'features/datingSafetyPolicyModal/model'
import {videoPlayerModel} from 'features/videoPlayer'

const sentryReduxEnhancer = createReduxEnhancer({
  attachReduxState: true
})

export const reducers = {
  app: appModel.reducer,
  favorites: favoriteModel.reducer,
  user: userModel.reducer,
  searchPeople: searchPeopleModel.reducer,
  dialogs: dialogsModel.reducer,
  messages: messagesModel.reducer,
  letters: lettersModel.reducer,
  virtualGifts: virtualGiftsModel.reducer,
  videoPlayer: videoPlayerModel.reducer,
  stickers: stickersModel.reducer,
  profile: profileModel.reducer,
  profileMedia: profileMediaModel.reducer,
  myMedia: myMediaModel.reducer,
  chatPair: chatPairModel.reducer,
  notifications: notificationsModel.reducer,
  payment: paymentModel.reducer,
  gallery: galleryModel.reducer,
  datingSafetyPolicy: datingSafetyPolicyModel.reducer,
  interests: interestsModel.reducer,
  orders: ordersModel.reducer,
  realGifts: realGiftsModel.reducer,
  threads: threadsModel.reducer,
  topics: topicsModel.reducer,
  introVideo: introVideoModel.reducer,
}

export const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer)
})
