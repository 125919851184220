import {Axios} from '@asocial/fe-utils'
import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {useSelector} from 'react-redux'
import {sentryTrackError} from 'shared/lib/sentry'
import {CursorType, ErrorResponsesType, SearchUser} from 'shared/types'
import {SearchFavoritesTabKeys} from '../config'
import {GetFavoritesResponse} from '../types'

export type initialStateProps = {
  cursor: CursorType
  list: SearchUser[]
  tab: SearchFavoritesTabKeys
}

export const initialStateFavoriteModel: initialStateProps = {
  cursor: '',
  list: [],
  tab: SearchFavoritesTabKeys.ALL
}

export const favoriteModel = createSlice({
  name: 'favorites',
  initialState: initialStateFavoriteModel,
  reducers: {
    addItemsFavoriteList: (state, {payload}: PayloadAction<{cursor: CursorType; data: SearchUser[]}>) => {
      state.cursor = payload.cursor
      state.list.push(...payload.data)
    },
    reverseFavoriteInList: (state, {payload}: PayloadAction<{id: string; isFavorite: boolean}>) => {
      state.list = state.list.map((item) =>
        item.id === payload.id ? {...item, isFavorite: !payload.isFavorite} : item
      )
    },
    setFavoriteTab: (state, {payload}: PayloadAction<SearchFavoritesTabKeys>) => {
      state.tab = payload
      state.cursor = ''
      state.list = []
    },
    clearFavorites: () => initialStateFavoriteModel
  }
})

export const {addItemsFavoriteList, reverseFavoriteInList, setFavoriteTab, clearFavorites} = favoriteModel.actions

export const useFavoriteList = () => useSelector((state: RootState) => state.favorites.list)

export const useFavoriteCursor = () => useSelector((state: RootState) => state.favorites.cursor)

export const useFavoriteTab = () => useSelector((state: RootState) => state.favorites.tab)

const urlFavorites = (tab: SearchFavoritesTabKeys) =>
  tab === SearchFavoritesTabKeys.ALL ? 'activity/favorites' : `activity/favorites/${tab}`

export const GetFavorites = (dispatch: Dispatch) =>
  useQuery<GetFavoritesResponse, ErrorResponsesType, GetFavoritesResponse, [string, initialStateProps]>({
    queryKey: ['favorites', useSelector((state: RootState) => state.favorites)],
    queryFn: ({queryKey: [_, {cursor, tab}]}) =>
      Axios.get(urlFavorites(tab), {
        params: {
          limit: 25,
          cursor: cursor || null
        }
      })
        .then(({data}) => {
          dispatch(addItemsFavoriteList(data))
          return data
        })
        .catch((error) => {
          sentryTrackError(`Favorite fetching error: ${JSON.stringify(error)}`)
          throw error
        }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })
