import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'

type DatingSafetyPolicyProps = {
  isOpen: boolean
}

export const initialStateDatingSafetyPolicyModel: DatingSafetyPolicyProps = {
  isOpen: false
}

export const datingSafetyPolicyModel = createSlice({
  name: 'datingSafetyPolicy',
  initialState: initialStateDatingSafetyPolicyModel,
  reducers: {
    setIsOpenDatingSafetyPolicy: (state, {payload}: PayloadAction<boolean>) => {
      state.isOpen = payload
    }
  }
})

export const {setIsOpenDatingSafetyPolicy} = datingSafetyPolicyModel.actions

export const useDatingSafetyPolicyIsOpen = () => useSelector((state: RootState) => state.datingSafetyPolicy.isOpen)
