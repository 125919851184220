import {Axios} from '@asocial/fe-utils'
import {createSelector, createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {useSelector} from 'react-redux'
import {ThreadType} from 'entities/inbox/types'
import {DialogItemType, DialogType} from 'entities/messages'
import {trackEvent} from 'entities/tracking/lib'
import {getValueFromArray} from 'shared/lib'
import {CursorType, ErrorResponsesType} from 'shared/types'
import {NotificationTypesList} from '../config'
import {
  GetNotificationsResponse,
  NeutralNotificationTypes,
  NotificationKeysTypes,
  NotificationType,
  NotificationTypes
} from '../types'

const maxPersonalStack = 5

export type PersonalStackType = {
  type: 'dialog' | 'thread'
  item: DialogItemType | ThreadType
  isNew: boolean
  id: number
}

type NotificationsModelProps = {
  cursor: CursorType
  list: NotificationType[]
  filter: NotificationKeysTypes
  filteredList: NotificationType[]
  stackNotifications: {
    type: 'notification' | 'dialog' | 'thread'
    item: NotificationType | DialogItemType | ThreadType
  }[]
  personalStackNotifications: PersonalStackType[]
  matches: NotificationType[]
  counters: Record<NotificationKeysTypes, number> | null
  newNotificationCount: number
}

const initialState: NotificationsModelProps = {
  cursor: '',
  list: [],
  filter: 'all',
  filteredList: [],
  stackNotifications: [],
  personalStackNotifications: [],
  matches: [],
  counters: null,
  newNotificationCount: 0
}

const typeIsAllMatch = (type: NotificationTypes | NeutralNotificationTypes) =>
  type === NotificationTypes.MATCH || type === NotificationTypes.POSSIBLE_MATCH

export const notificationsModel = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotifications: (state, {payload}: PayloadAction<{data: NotificationType[]}>) => {
      state.list = payload.data
    },
    addNotificationsFiltered: (state, {payload}: PayloadAction<{data: NotificationType[]; cursor: CursorType}>) => {
      state.cursor = payload.cursor
      state.filteredList.push(...payload.data)
    },
    addNotificationUp: (state, {payload}: PayloadAction<NotificationType>) => {
      state.list.unshift(payload)
      state.newNotificationCount++

      if (state.cursor === '') return

      // adding for filteredList
      if (
        state.filter === 'all' ||
        state.filter === payload.type ||
        (state.filter === 'allMatch' && typeIsAllMatch(payload.type))
      ) {
        state.filteredList.unshift(payload)
      }

      // Update counters
      state.counters && state.counters.all++

      if (
        payload.type === NotificationTypes.LIKE ||
        payload.type === NotificationTypes.VISIT ||
        payload.type === NotificationTypes.FAVORITE
      ) {
        state.counters && state.counters[payload.type]++
      }

      if (typeIsAllMatch(payload.type)) {
        state.counters && state.counters.allMatch++
      }
    },
    updateNewNotificationCount: (state, {payload}: PayloadAction<number>) => {
      state.newNotificationCount = payload
    },
    clearNewNotificationCount: (state) => {
      state.newNotificationCount = 0
    },
    setFilterNotifications: (state, {payload}: PayloadAction<NotificationKeysTypes>) => {
      state.filter = payload
      state.filteredList = []
      state.cursor = ''
    },
    setNotificationsCounters: (state, {payload}: PayloadAction<Record<NotificationKeysTypes, number>>) => {
      state.counters = payload
    },
    // stack notifications
    addNotificationToStack: (state, {payload}: PayloadAction<NotificationType>) => {
      state.stackNotifications.push({type: 'notification', item: payload})
    },
    addDialogToStack: (state, {payload}: PayloadAction<DialogItemType>) => {
      if (payload.interlocutor.id === payload.lastMessage.to.id) return

      if (state.stackNotifications.length === 0) {
        state.stackNotifications.push({type: 'dialog', item: payload})
        return
      }

      let indexLastDialog = 0

      for (let i = state.stackNotifications.length - 1; i > 0; i--) {
        if (state.stackNotifications[i].type === 'dialog') {
          indexLastDialog = i
          break
        }
      }

      state.stackNotifications.splice(indexLastDialog < 0 ? 1 : indexLastDialog + 1, 0, {type: 'dialog', item: payload})
    },
    addThreadToStack: (state, {payload}: PayloadAction<ThreadType>) => {
      state.stackNotifications.push({type: 'thread', item: payload})
    },
    deleteStackNotification: (state, {payload: message}: PayloadAction<string | undefined>) => {
      const [notification] = state.stackNotifications.splice(0, 1)

      if (notification && notification.type === 'notification') {
        const item = notification.item as NotificationType

        trackEvent({
          event: item.type,
          context: {message}
        })
      }
    },
    clearStackNotification: (state) => {
      state.stackNotifications = []
    },
    // stack personal notifications
    addToPersonalStack: (state, {payload}: PayloadAction<Pick<PersonalStackType, 'type' | 'item'>>) => {
      if (payload.type === 'dialog') {
        if (
          payload.item.type === DialogType.REQUEST &&
          state.personalStackNotifications.find(
            (item) =>
              payload.item.type === DialogType.REQUEST && item.item.interlocutor.id === payload.item.interlocutor.id
          )
        ) {
          state.personalStackNotifications = state.personalStackNotifications.filter(
            (item) =>
              payload.item.type === DialogType.REQUEST && item.item.interlocutor.id !== payload.item.interlocutor.id
          )
        }
      }

      state.personalStackNotifications.unshift({...payload, isNew: true, id: Date.now()})

      if (state.personalStackNotifications.length > maxPersonalStack) {
        state.personalStackNotifications = state.personalStackNotifications.slice(0, maxPersonalStack)
      }
    },
    clearNewPersonalStack: (state) => {
      state.personalStackNotifications = state.personalStackNotifications.map((item) => ({...item, isNew: false}))
    },
    deleteToPersonalStack: (state, {payload: id}: PayloadAction<number>) => {
      state.personalStackNotifications = state.personalStackNotifications.filter((item) => item.id !== id)
    },
    // matches
    addMatch: (state, {payload}: PayloadAction<NotificationType>) => {
      state.matches.push(payload)
    },
    deleteMatch: (state) => {
      state.matches.splice(0, 1)
    }
  }
})

export const {
  addNotifications,
  addNotificationsFiltered,
  deleteStackNotification,
  setFilterNotifications,
  addNotificationUp,
  addNotificationToStack,
  setNotificationsCounters,
  addDialogToStack,
  clearNewNotificationCount,
  addToPersonalStack,
  clearNewPersonalStack,
  deleteToPersonalStack,
  addMatch,
  deleteMatch,
  clearStackNotification,
  updateNewNotificationCount,
  addThreadToStack
} = notificationsModel.actions

export const GetNotifications = (dispatch: Dispatch) =>
  useQuery<
    GetNotificationsResponse,
    ErrorResponsesType,
    GetNotificationsResponse,
    [string, CursorType, NotificationKeysTypes]
  >({
    queryKey: [
      'getNotifications',
      useSelector((state: RootState) => state.notifications.cursor),
      useSelector((state: RootState) => state.notifications.filter)
    ],

    queryFn: ({queryKey: [_, cursor, filter]}) =>
      Axios.get('activity/notifications', {
        params: {
          cursor: cursor || null,
          types: getValueFromArray(NotificationTypesList, filter).value
        }
      }).then(({data}) => {
        dispatch(addNotificationsFiltered(data))
        return data
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })

export const useNotificationsList = () => useSelector((state: RootState) => state.notifications.list)

export const useNotificationsFilteredList = () => useSelector((state: RootState) => state.notifications.filteredList)

export const useNotificationsCursor = () => useSelector((state: RootState) => state.notifications.cursor)

export const useTopNotification = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.notifications.stackNotifications,
      (stack) => stack[0]
    )
  )

export const useFilterNotificationsList = () => useSelector((state: RootState) => state.notifications.filter)

export const useNotificationsCounters = () => useSelector((state: RootState) => state.notifications.counters)

export const useNewNotificationCount = () => useSelector((state: RootState) => state.notifications.newNotificationCount)

export const usePersonalNotificationList = () =>
  useSelector((state: RootState) => state.notifications.personalStackNotifications)

export const useMatch = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.notifications.matches,
      (matches) => matches[0]
    )
  )
