export const deepEqual: (x: Record<string, any>, y: Record<string, any>) => boolean = (
  x: Record<string, any>,
  y: Record<string, any>
) => {
  const ok = Object.keys
  const tx = typeof x
  const ty = typeof y

  return x && y && tx === 'object' && tx === ty ?
      ok(x).length === ok(y).length && ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y
}

export const convertNullToString = (value: [string, any][]): object =>
  JSON.parse(JSON.stringify(Object.fromEntries(value.map(([key, value]) => [key, value === null ? '' : value]))))
