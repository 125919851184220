import * as Sentry from '@sentry/react'

const blockedErrors = [
  'connection_blocked',
  'Network Error',
  'user_blocked',
  'Request aborted',
  'Too many requests',
  'timeout exceeded'
]

export const sentryTrackError = (error: any) => {
  if (!error) return

  if (
    (typeof error === 'string' || Array.isArray(error)) &&
    blockedErrors.some((blockedError) => error.includes(blockedError))
  )
    return

  return Sentry.captureException(error)
}
