import {FC, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Cross} from 'shared/ui/cross'
import {setVideoPlayer, useVideoPlayerData} from './model'
import styles from './styles.module.scss'

type VideoPlayerProps = {
  rootStyles?: string
  iframeStyles?: string
}

export const VideoPlayer: FC<VideoPlayerProps> = ({rootStyles, iframeStyles}) => {
  const dispatch = useDispatch()
  const {iframe, poster} = useVideoPlayerData()
  const closeVideoPlayer = () => dispatch(setVideoPlayer({}))

  useEffect(() => {
    const keydown =  (event: KeyboardEvent) => {
      if (iframe && poster && event.key === 'Escape') {
        closeVideoPlayer()
      }
    }

    window.addEventListener('keydown', keydown)
    return () => window.removeEventListener('keydown', keydown)
  }, [iframe, poster])

  if (!iframe || !poster) return <></>

  return (
    <div className={`${styles.root} ${rootStyles || ''}`}>
      <iframe
        className={`${styles.iframe} ${iframeStyles || ''}`}
        src={`${iframe}?poster=${poster}&autoplay=true`}
        allow='accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;'
        allowFullScreen
      />
      <Cross
        onClose={closeVideoPlayer}
        className={styles.close}
      />
    </div>
  )
}
