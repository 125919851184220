import ListSubheader from '@mui/material/ListSubheader'
import MenuItem from '@mui/material/MenuItem'
import MuiSelect from '@mui/material/Select'
import {SelectProps as MuiSelectProps} from '@mui/material/Select/Select'
import React, {useMemo, useRef, useState} from 'react'
import {ReactComponent as DropDownIcon} from 'shared/ui/icons/arrow-down-icon.svg'
import './styles.scss'
import 'shared/ui/styles/select.scss'
import 'shared/ui/styles/popup.scss'
import {NoMatches} from './ui/noMatches'
import {SearchField} from './ui/searchField'

export type SelectSearchTransform = MuiSelectProps & {
  width?: string
  sizePopup?: 'xs' | 'md' | 'xl'
  horizontalPopup?: 'left' | 'right'
  placeholder?: string
}

export type SelectSearchProps = SelectSearchTransform & {
  options: {
    id: string
    [key: string]: string
  }[]
  renderOptions: (option: any, index: number) => React.ReactNode
  renderValue?: (option: any) => React.ReactNode
  filter: (option: any, searchValue: string) => boolean
}

export const SelectSearch: React.FC<SelectSearchProps> = ({
  className,
  options,
  renderOptions,
  renderValue,
  filter,
  placeholder,
  sizePopup,
  horizontalPopup,
  style,
  width,
  ...rest
}) => {
  const [searchText, setSearchText] = useState('')
  const inputRef = useRef<HTMLElement>(null)
  const selectRef = useRef<HTMLElement>(null)
  const displayedOptions = useMemo(
    () => options.filter((option) => filter(option, searchText)),
    [searchText, filter, options]
  )

  return (
    <MuiSelect
      {...rest}
      ref={selectRef}
      className={`override-select ${className}`}
      IconComponent={DropDownIcon}
      onClose={() => {
        selectRef?.current?.classList.remove('Mui-focused')
        setSearchText('')
      }}
      renderValue={(key: any) => {
        if (!key && placeholder) {
          return <div className='override-select-placeholder'>{placeholder}</div>
        }

        const option = options.find((option) => option.id === key)
        if (!option) return <></>
        if (renderValue) {
          return renderValue(option)
        }
        return <>{option.label}</>
      }}
      MenuProps={{
        autoFocus: false,
        classes: {
          paper: `override-popup-paper override-popup-paper-${sizePopup || 'md'}`,
          list: 'override-popup-list'
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: horizontalPopup || 'left'
        },
        transformOrigin: {
          vertical: -4,
          horizontal: horizontalPopup || 'left'
        }
      }}
      style={{width, ...style}}
      onOpen={() => {
        selectRef?.current?.classList.add('Mui-focused')
        setTimeout(() => inputRef.current && inputRef.current.focus(), 0)
      }}
    >
      <ListSubheader className='override-selectSearch-list-subheader'>
        <SearchField inputRef={inputRef} onChange={(e) => setSearchText(e.target.value)} />
      </ListSubheader>
      {placeholder ?
        <MenuItem value='' className='override-selectSearch-placeholder'>
          <div className='override-selectSearch-placeholder'>{placeholder}</div>
        </MenuItem>
      : null}
      {displayedOptions.length ? displayedOptions.map(renderOptions) : <NoMatches />}
    </MuiSelect>
  )
}
