import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'
import {WalletType} from 'shared/types'
import {DeclinePaymentReason, DeclineReasonObject, PackageType, PaymentStatus} from './types'

export const DeclineReason: Record<DeclinePaymentReason, DeclineReasonObject> = {
  [DeclinePaymentReason.THREE_DS_REQUIRED]: {
    title: '3D Secure Required',
    description:
      'Contact your bank for details and possible resolution, or proceed with an order again via another card or payment option (e.g., PayPal).'
  },
  [DeclinePaymentReason.THREE_DS_FAILED]: {
    title: '3D Secure Failed',
    description:
      'Retry and confirm your payment via bank app / SMS / email, or contact your bank to find 3D secure verification code.'
  },
  [DeclinePaymentReason.TIMED_OUT]: {
    title: 'Timed Out or Cancelled',
    description:
      "Payment wasn't completed, retry and finalize your payment, or proceed with an order via another card or payment option."
  },
  [DeclinePaymentReason.PAYMENT_CANCELLED]: {
    title: 'Payment Cancelled',
    description:
      "Payment wasn't completed, retry and finalize your payment, or proceed with an order via another card or payment option."
  },
  [DeclinePaymentReason.INSUFFICIENT_BALANCE]: {
    title: 'Insufficient Balance',
    description:
      'Insufficient funds to proceed, place an order again after topping up your account or increasing your card credit limit.'
  },
  [DeclinePaymentReason.TRANSACTION_ERROR]: {
    title: 'General Decline',
    description:
      'Retry or contact your bank for details and resolution; another card or payment options are also available (e.g., PayPal).'
  },
  [DeclinePaymentReason.LIMIT_EXCEEDED]: {
    title: 'Frequency Limit Exceeded',
    description: 'Exceeded attempts to pay with this card for 24 hours, try alternative card or payment option'
  },
  [DeclinePaymentReason.PAYMENT_ERROR]: {
    title: 'Payment Error',
    description:
      'Retry or contact your bank for details and resolution; another card or payment options are also available (e.g., PayPal)'
  },
  [DeclinePaymentReason.FORBIDDEN_CARD]: {
    title: 'Forbidden Card',
    description:
      'Contact your bank for details and possible resolution, or proceed with an order again via another card or payment option.'
  },
  [DeclinePaymentReason.FROZEN_CARD]: {
    title: '',
    description: ''
  },
  [DeclinePaymentReason.CARD_NOT_SUPPORTED]: {
    title: 'General Decline',
    description:
      "Your card isn't supported, proceed with an order again via another card or payment option, or contact your bank for details and possible resolution."
  },
  [DeclinePaymentReason.NOT_ENOUGH_DATA]: {
    title: 'General Decline',
    description:
      'Retry by entering the data on the card and ZIP/POST code according to your billing address, or proceed with an order again via another card or payment option.'
  },
  [DeclinePaymentReason.INVALID_CARD_DATA]: {
    title: 'Invalid Card Data',
    description:
      'Retry by entering your name, card number, expiration date, CVV like on the card, and ZIP/POST code according to your billing address.'
  },
  [DeclinePaymentReason.INVALID_CARD_NUMBER]: {
    title: 'Invalid Card Number',
    description:
      'Retry by entering the card number as on the card (16 digits on the front of the card), or proceed with an order again via another card or payment option (e.g., PayPal).'
  },
  [DeclinePaymentReason.INVALID_EXPIRATION_DATE]: {
    title: 'Invalid Expiration Date',
    description:
      'Retry with the expiration date exactly as it is written on the front of your card (month/year), or proceed with an order again via another card or payment option.'
  },
  [DeclinePaymentReason.INVALID_CARDHOLDER]: {
    title: 'Invalid Cardholder',
    description:
      'Retry by entering the cardholder name as on the card (if absent, use your full name), or proceed with an order again via another card or payment option (e.g., PayPal).'
  },
  [DeclinePaymentReason.INVALID_CVV]: {
    title: 'Invalid CVV',
    description:
      'Retry with the correct CVV (3-5 digits on the back of your card), or proceed with an order again via another card or payment option (e.g., PayPal).'
  },
  [DeclinePaymentReason.GENERAL_DECLINE]: {
    title: 'General Decline',
    description:
      'Contact your bank for details and possible resolution, or proceed with an order again via another card or payment option (e.g., PayPal).'
  }
}

type PaymentStatusModalType = {
  isOpen: boolean
  status: PaymentStatus
  error?: DeclinePaymentReason | string
  reason?: DeclinePaymentReason | string
  processPackage: PackageType
  descriptor?: string
}

type ConfirmPayModalType = PackageType & {isOpen: boolean; isNewMethod?: boolean}

type CardData = {
  cardNumber: string
  expireDate: string
  cvv: string
  cardHolder: string
  zipCode: string
}

type initialStateProps = {
  isLoadingWallet: boolean
  wallet: WalletType
  confirmPayModal: ConfirmPayModalType
  packages: PackageType[]
  paymentStatusModal: PaymentStatusModalType
  card: CardData
  isPackageModalOpen: boolean
}

export const initialStatePaymentModel: initialStateProps = {
  isLoadingWallet: false,
  wallet: {
    credits: 0,
    freeCredits: 0,
    stickers: 0,
    photos: 0,
    videos: 0,
    virtualGifts: 0
  },
  confirmPayModal: {
    isOpen: false,
    credits: 0,
    stickers: 0,
    virtualGifts: 0,
    originPrice: 0,
    photos: 0,
    price: 0,
    id: 0,
    videos: 0,
    name: '',
    autoChargeable: true,
    isNewMethod: false
  },
  packages: [],
  paymentStatusModal: {
    isOpen: false,
    status: PaymentStatus.IN_PROGRESS,
    error: '',
    processPackage: {
      credits: 0,
      stickers: 0,
      virtualGifts: 0,
      originPrice: 0,
      photos: 0,
      price: 0,
      id: 0,
      videos: 0,
      name: '',
      autoChargeable: true
    }
  },
  card: {
    cardNumber: '',
    expireDate: '',
    cvv: '',
    cardHolder: '',
    zipCode: ''
  },
  isPackageModalOpen: false
}

export const paymentModel = createSlice({
  name: 'payment',
  initialState: initialStatePaymentModel,
  reducers: {
    setCredits: (state, {payload}: PayloadAction<WalletType>) => {
      state.wallet = payload
      state.isLoadingWallet = true
    },
    setPackages: (state, {payload}: PayloadAction<PackageType[]>) => {
      state.packages = payload
    },
    setConfirmPayModal: (state, {payload}: PayloadAction<Partial<ConfirmPayModalType>>) => {
      state.confirmPayModal = {...state.confirmPayModal, ...payload}
    },
    setPaymentModal: (state, {payload}: PayloadAction<Partial<PaymentStatusModalType>>) => {
      state.paymentStatusModal = {...state.paymentStatusModal, ...payload}
    },
    setPackageModalOpen: (state, {payload}: PayloadAction<boolean>) => {
      state.isPackageModalOpen = payload
    },
    setPaymentFields: (state, {payload}: PayloadAction<Record<string, string>>) => {
      state.card = {...state.card, ...payload}
    }
  }
})

export const {setPackages, setConfirmPayModal, setCredits, setPaymentModal, setPackageModalOpen, setPaymentFields} =
  paymentModel.actions

export const useIsLoadingWallet = () => useSelector((state: RootState) => state.payment.isLoadingWallet)

export const useWallet = () => useSelector((state: RootState) => state.payment.wallet)

export const usePaymentPackages = () => useSelector((state: RootState) => state.payment.packages)

export const useConfirmPayModal = () => useSelector((state: RootState) => state.payment.confirmPayModal)

export const usePaymentStatusModal = () => useSelector((state: RootState) => state.payment.paymentStatusModal)

export const useProcessPackage = () =>
  useSelector((state: RootState) => state.payment.paymentStatusModal.processPackage)

export const usePaymentDescriptor = () => useSelector((state: RootState) => state.payment.paymentStatusModal.descriptor)

export const usePaymentError = (): DeclinePaymentReason | string | undefined =>
  useSelector((state: RootState) => state.payment.paymentStatusModal.error)

export const usePackageModal = () => useSelector((state: RootState) => state.payment.isPackageModalOpen)

export const usePaymentFields = () => useSelector((state: RootState) => state.payment.card)
