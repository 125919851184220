import {CountryKeys} from 'shared/lib'
import {CursorType} from 'shared/types'
import {DialogItemType} from './model'

export type ChatUserType = {
  id: string
  name: string
  avatarUrl?: string
  isOnline: boolean
  isBlocked: boolean
  isConfirmed: boolean
  age: number
  isPopular: boolean
  city?: string
  country: CountryKeys
}

type VirtualGiftMessageRequestType = {
  id: number
  text: string | null
  gradient: string | null
}

type PhotoMessageRequestType = {
  key: string
}

type VideoMessageRequestType = {
  cfId: string
}

type AudioMessageRequestType = {
  key: string
  duration: number
}

export type SendMessageRequestType =
  | {text: string}
  | {id: number}
  | VirtualGiftMessageRequestType
  | PhotoMessageRequestType
  | VideoMessageRequestType
  | AudioMessageRequestType

export enum PhotoTypes {
  attachment = 'attachment',
  avatar = 'avatar',
  VIDEO_AVATAR = 'video-avatar',
  banner = 'banner',
  private = 'private',
  public = 'public',
  app = 'app'
}

export type ImageType = {
  key: string
  url: string
}

export type PhotoType = {
  id: string
  type: PhotoTypes
  image: ImageType
}

export type StreamType = {
  cfId: string
  url: string
  iframeUrl: string
  previewUrl: string
  gifUrl: string
  jpgUrl: string
}

export type VirtualGiftType = {
  id: number
  name: string
  price: number
  data: {
    url: string
  }
}

export type GetChatRequestsResponse = {
  cursor: CursorType
  data: DialogItemType[]
  totalCount: number
}
