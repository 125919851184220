import {useEffect, useState} from 'react'
import {currentMirror} from './config'

const imgArr = [
  {
    key: 'logo',
    src: 'logo.svg'
  }
]

type imgType = {
  logo: string
}

export const img: imgType = {
  logo: ''
}

export function useImgLoad(): boolean {
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    (async () => {
      for (const {key, src} of imgArr) {
        if (!(key in img)) continue

        img[key as keyof imgType] = (await import(`shared/mirrors/${currentMirror}/img/${src}`)).default
      }
    })()

    setIsLoad(true)
  }, [])

  return isLoad
}
