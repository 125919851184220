import {Axios} from '@asocial/fe-utils'
import {createSelector, createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {OrderType, RealGiftCategoryType, RealGiftType} from 'entities/realGifts/types'
import {ErrorResponsesType} from 'shared/types'

export type RealGiftsModelType = {
  list: RealGiftCategoryType[]
  order: RealGiftType[]
  successOrder: OrderType | null
  deliveryDate: Record<string, string>
  counter: number
}

export const initialStateRealGiftsModel: RealGiftsModelType = {
  list: [],
  order: [],
  successOrder: null,
  deliveryDate: {},
  counter: 0
}

export const realGiftsModel = createSlice({
  name: 'realGifts',
  initialState: initialStateRealGiftsModel,
  reducers: {
    realGiftsSetData: (state, {payload}: PayloadAction<RealGiftCategoryType[]>) => {
      state.list = payload
    },
    setOrder: (state, {payload}: PayloadAction<RealGiftType[]>) => {
      state.order = payload
    },
    addToOrder: (state, {payload}: PayloadAction<RealGiftType>) => {
      state.order.push(payload)
    },
    deleteToOrder: (state, {payload: idRealGift}: PayloadAction<number>) => {
      state.order = state.order.filter((item) => item.id !== idRealGift)
    },
    clearOrder: (state) => {
      state.order = []
    },
    setSuccessOrder: (state, {payload: order}: PayloadAction<OrderType>) => {
      state.successOrder = order
    },
    clearSuccessOrder: (state) => {
      state.successOrder = null
    },
    updateDeliveryDate: (state, {payload}: PayloadAction<{idUser: string; date: string | null}>) => {
      if (!payload.date) {
        delete state.deliveryDate[payload.idUser]
        return
      }

      state.deliveryDate[payload.idUser] = payload.date
    },
    updateRealGiftCounter: (state, {payload}: PayloadAction<number>) => {
      state.counter = payload
    }
  }
})

export const {
  addToOrder,
  deleteToOrder,
  clearOrder,
  setSuccessOrder,
  clearSuccessOrder,
  updateDeliveryDate,
  updateRealGiftCounter
} = realGiftsModel.actions

export const GetRealGifts = (dispatch: Dispatch) =>
  useQuery<AxiosResponse<{data: RealGiftCategoryType[]}>, ErrorResponsesType>({
    queryKey: ['getRealGifts'],
    queryFn: ({signal}) =>
      Axios.get('/real-gifts', {signal}).then(({data: response}) => {
        dispatch(realGiftsModel.actions.realGiftsSetData(response.data))
        return response
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })

export const GetOrder = (idUser: string, dispatch: Dispatch) =>
  useQuery<AxiosResponse<{data: RealGiftType[]}>, ErrorResponsesType>({
    queryKey: ['getOrder', idUser],
    queryFn: ({signal}) =>
      Axios.get(`/real-gifts/${idUser}/order`, {signal}).then(({data: response}) => {
        dispatch(realGiftsModel.actions.setOrder(response.data))
        return response
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })

export const useRealGiftsList = () => useSelector((state: RootState) => state.realGifts.list)

export const useRealGiftsCount = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.realGifts.list,
      (list) => list.reduce((prev, curr) => prev + curr.realGifts.length, 0)
    )
  )

export const useRealGiftsOrder = () => useSelector((state: RootState) => state.realGifts.order)

export const useRealGiftsOrderPrice = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.realGifts.order,
      (list) => list.reduce((prev, curr) => prev + curr.price, 0)
    )
  )

export const useRealGiftsSuccessOrder = () => useSelector((state: RootState) => state.realGifts.successOrder)

export const useRealGiftsDeliveryDate = () => useSelector((state: RootState) => state.realGifts.deliveryDate)

export const useRealGiftCounter = () => useSelector((state: RootState) => Boolean(state.realGifts.counter))
