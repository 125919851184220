import Slider from '@mui/material/Slider'
import React, {useState, createRef, useEffect, useContext} from 'react'
import {Cropper as ReactCropper, ReactCropperElement} from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import {Title} from 'features/title'
import {Button} from 'shared/ui/buttons'
import {Image} from 'shared/ui/icons'
import {CropperContext, CropperContextType} from '../model'
import styles from './styles.module.scss'
import './styles.scss'

const files: File[] = []

export const Cropper: React.FC = () => {
  const {isOpen, onSave, src, closeCropper, isMultiple, setZoom, images, zoomTo, isRounded, aspectRatio} = useContext(
    CropperContext
  ) as CropperContextType
  const [currIndex, setCurrIndex] = useState(0)
  const [isLoad, setIsLoad] = useState(false)
  const cropperRef = createRef<ReactCropperElement>()

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper === 'undefined' || !onSave) return

    cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
      setIsLoad(true)

      if (!isMultiple) {
        return onSave(new File([blob as BlobPart], 'croppedImage', {type: 'image/png'})).finally(() => setIsLoad(false))
      }

      //If multiple cropper
      files.push(new File([blob as BlobPart], 'croppedImage', {type: 'image/png'}))
      setZoom(0.3)
      if (currIndex + 1 === images?.length) return onSave([...files]).finally(() => setIsLoad(false))
      setCurrIndex(currIndex + 1)
    })
  }

  useEffect(
    () =>
      //clear array after unmount
      () => {
        files.splice(0, files.length)
      },
    []
  )

  if (!isOpen) return <></>

  return (
    <div className={styles.backdrop}>
      <div className={styles.container}>
        <Title className={styles.header} onCloseHandler={closeCropper}>
          <h5 className={styles.title}>Crop image</h5>
        </Title>
        <div className={`${styles.content} cropperContainer ${isRounded ? 'cropperContainerRounded' : ''}`}>
          <ReactCropper
            ref={cropperRef}
            viewMode={3}
            dragMode='move'
            minCropBoxHeight={200}
            minCropBoxWidth={200}
            minContainerHeight={300}
            center={false}
            guides={false}
            highlight={false}
            background={false}
            zoomOnTouch={false}
            zoomOnWheel={false}
            checkOrientation={false}
            zoomTo={zoomTo}
            src={!isMultiple ? src : images && images[currIndex]}
            aspectRatio={aspectRatio}
          />
          <div className={styles.slider}>
            <div className={styles.slider__icon}>
              <Image />
            </div>
            <Slider
              className={'override-cropper__slider'}
              value={zoomTo}
              min={0}
              max={5}
              step={0.01}
              onChange={(_, value) => setZoom(Number(value))}
            />
            <div className={`${styles.slider__icon} ${styles.slider__icon_large}`}>
              <Image />
            </div>
          </div>
          <div className={styles.btnContainer}>
            {currIndex !== 0 ?
              <Button className={styles.btn} styleType='secondary' onClick={() => setCurrIndex(currIndex - 1)}>
                Back {currIndex}/{images?.length}
              </Button>
            : <div />}

            <Button className={styles.btn} styleType='primary' onClick={getCropData} isLoading={isLoad}>
              {currIndex + 1 === images?.length || !isMultiple ? 'Save' : `Next ${currIndex + 1}/${images?.length}`}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
