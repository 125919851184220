import {FingerprintJSPro} from '@fingerprintjs/fingerprintjs-pro-react'

export const MAX_INTERESTS_LENGTH = 50

export const LIMITED_PACKAGE_ID = 2

export enum PATH {
  PRIVACY_POLICY = '/privacy-policy',
  COMMUNITY_RULES = '/community-rules',
  DATING_SAFETY = '/dating-safety',
  TERMS_OF_USE = '/terms-of-use',
  ANTI_TRAFFICKING_POLICY = '/anti-trafficking-policy',
  CREDENTIALS_STORAGE_AGREEMENT = '/credentials-storage-agreement'
}

export const TERMS_URL = [
  PATH.PRIVACY_POLICY,
  PATH.COMMUNITY_RULES,
  PATH.DATING_SAFETY,
  PATH.TERMS_OF_USE,
  PATH.ANTI_TRAFFICKING_POLICY,
  PATH.CREDENTIALS_STORAGE_AGREEMENT,
  'crypto-pay',
  'threeDs',
  'blog'
]

export enum SettingsTabs {
  EDIT_PROFILE = 'editProfile',
  ACCOUNT = 'account',
  NOTIFICATIONS = 'notifications',
  BILLING = 'billing',
  DEACTIVATION = 'deactivation'
}

export const SettingsItemList = [
  {id: SettingsTabs.EDIT_PROFILE, label: 'Edit profile'},
  {id: SettingsTabs.ACCOUNT, label: 'Account'},
  {id: SettingsTabs.NOTIFICATIONS, label: 'Notifications'},
  {id: SettingsTabs.BILLING, label: 'Billing'},
  {id: SettingsTabs.DEACTIVATION, label: 'Deactivation'}
]

export enum GUIDE_SECTIONS {
  TURN_ON_CHROME_MAC = 'turn-on-chrome-mac',
  ALLOW_CHROME_MAC = 'allow-chrome-mac',
  ALLOW_CHROME_WINDOWS = 'allow-chrome-windows',
  ALLOW_CHROME_MOBILE = 'allow-chrome-mobile',
  ALLOW_SAFARI_MAC = 'allow-safari-mac'
}

export const SETTINGS_NOTIFICATIONS_PATH = '/settings/notifications'

export enum TEXT_ERRORS {
  TOO_MANY_REQUESTS = 'Too many requests! Please wait and try again',
  INTERNAL_POLICIES = 'You have been blocked by internal policies'
}

export const FP_OPTIONS_DEV = {
  apiKey: 'JsiqMQt5vHIUatQeHxWp',
  endpoint: ['https://gochatty.asocial-team.com/H8HqnkObERaHWxxS/8KSqTcNjEG4TW3FN', FingerprintJSPro.defaultEndpoint],
  scriptUrlPattern: [
    'https://gochatty.asocial-team.com/H8HqnkObERaHWxxS/YfMBW7XTbe1U0B2a?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>',
    FingerprintJSPro.defaultScriptUrlPattern
  ]
}

export const FP_OPTIONS_PROD = {
  apiKey: 'JVqpBUOeZevDAMHAjN3S',
  endpoint: ['https://gochatty.com/zo7wt8FcwciK6I6j/3TyDDLsaShLbYC5i', FingerprintJSPro.defaultEndpoint],
  scriptUrlPattern: [
    'https://gochatty.com/zo7wt8FcwciK6I6j/YDskOwHAD0JiQHXQ?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>',
    FingerprintJSPro.defaultScriptUrlPattern
  ]
}

export const MIN_PAYMENTS_FOR_DISPLAY_SUPPORT_PHONE = 3

export enum FlirtcastMessages {
  MESSAGE_1 = "Unfortunately, I don't know any trick questioning, so may I enquire if you would like to have a chat?",
  MESSAGE_2 = "Isn't it a delightful day to have a dialogue?",
  MESSAGE_3 = "Do you consider yourself more romantic or pragmatic? I'm curious if we're compatible.",
  MESSAGE_4 = 'Hello! If you wanted to ask me a small favour, what would it be?',
  MESSAGE_5 = 'Hello! Are you looking for adventure and meeting interesting people?',
  MESSAGE_6 = "Greetings! Are you looking for a companion? I'm here!",
  MESSAGE_7 = 'Would you be interested in a conversation with someone who can listen to your thoughts?',
  MESSAGE_8 = 'Do you think we are a good match?',
  MESSAGE_9 = 'Are you determined to find someone? Why waste time when you can start talking to me?',
  MESSAGE_10 = "The weather's conducive to conversation, isn't it?",
  MESSAGE_11 = 'Hey! How long does it usually take you to fall in love with someone?',
  MESSAGE_12 = "How's it going? What qualities of people do you admire most?"
}

export const flirtcastMessages = Object.values(FlirtcastMessages)

export const INACTIVE_TIMER = 1000 * 60 * 10

export const MIN_LENGTH_REVIEW = 90

export const MAX_LENGTH_REVIEW = 115
