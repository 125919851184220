import {Axios} from '@asocial/fe-utils'
import axios, {AxiosResponse} from 'axios'
import {CountryKeys, getFPParams} from 'shared/lib'
import {ErrorResponsesType, Me, ChildrenCount, MaritalStatus, Education, ProfileType} from 'shared/types'
import {PhotoType} from '../messages'
import {
  ConfigResponse,
  GeoResponse,
  GoogleOAuthResponse,
  OAuthProps,
  RegisterUserParams,
  RegisterUserResponse,
  ValidationParams
} from './model'

export const GetGeoUser = (): Promise<AxiosResponse<GeoResponse, ErrorResponsesType>> => Axios.get('geo')

export const CheckMailValidation = (params: ValidationParams): Promise<AxiosResponse<any, ErrorResponsesType>> =>
  Axios.post('validate', params)

export const RegisterUser = (
  params: RegisterUserParams & {turnstile?: string | null}
): Promise<AxiosResponse<RegisterUserResponse, ErrorResponsesType>> =>
  Axios.post(
    `register${window.location.search}`,
    {...params, ...getFPParams()},
    {withCredentials: process.env.NODE_ENV === 'production'}
  )

export const UploadAvatar = (key: string): Promise<AxiosResponse<PhotoType, ErrorResponsesType>> =>
  Axios.put('avatar', {key})

export const DeleteAvatar = (key: string): Promise<AxiosResponse<PhotoType, ErrorResponsesType>> =>
  Axios.delete('avatar', {data: {key}})

export const UploadBanner = (key: string): Promise<AxiosResponse<PhotoType, ErrorResponsesType>> =>
  Axios.put('banner', {key})

export const GetConfig = (): Promise<AxiosResponse<ConfigResponse>> => Axios.get('/config')

export const GetUserByGoogle = (access_token: string): Promise<AxiosResponse<GoogleOAuthResponse>> =>
  axios.get(`https://people.googleapis.com/v1/people/me`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
      Accept: 'application/json'
    },
    params: {
      personFields: 'names'
    }
  })

export const OAuth = (params: OAuthProps, type: 'facebook' | 'google'): Promise<AxiosResponse<RegisterUserResponse>> =>
  Axios.post(`/oauth/${type}`, {...params, ...getFPParams()}, {withCredentials: process.env.NODE_ENV === 'production'})

export const GetAboutMe = (): Promise<AxiosResponse<Me, ErrorResponsesType>> => Axios.get('me')

type ProfileRequestType = {
  name: string
  dateBirth: string
  country: CountryKeys
  city: string | null
  maritalStatus: MaritalStatus | null
  childrenCount: ChildrenCount | null
  occupation: string | null
  education: Education | null
  height: number | null
  weight: number | null
  drinking: boolean | null
  smoking: boolean | null
  about: string | null
}

export const ChangeProfileInfo = (
  params: ProfileRequestType
): Promise<AxiosResponse<ProfileType, ErrorResponsesType>> => Axios.put('profile', params)

export const AddPhoto = (keys: string[]): Promise<AxiosResponse<{data: PhotoType[]}, ErrorResponsesType>> =>
  Axios.post('photos', {keys})

export const Deactivate = (): Promise<AxiosResponse<null>> => Axios.patch('deactivate')

export const Depersonalize = (email: string): Promise<AxiosResponse<null>> => Axios.patch('depersonalize', {email})

export const Reactivate = (): Promise<AxiosResponse<null>> => Axios.patch('reactivate')

export const ResetPassword = (email: string): Promise<AxiosResponse<null>> => Axios.post('/forgot-password', {email})

export const ChangeEmail = (email: string): Promise<AxiosResponse<null, ErrorResponsesType>> =>
  Axios.put('email', {email})

export const ChangePassword = (password: string): Promise<AxiosResponse<null, ErrorResponsesType>> =>
  Axios.put('password', {password})

export const VerifyEmail = (): Promise<AxiosResponse<null, null>> => Axios.post('verify-email')

export const ChangeTimezone = (timezone: string) => Axios.put('timezone', {timezone})

export const ChangeInterests = (ids: number[] | []): Promise<AxiosResponse<null>> => Axios.put(`/interests`, {ids})
