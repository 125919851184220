import {ChildrenCount, Drinking, Education, MaritalStatus, Smoking} from 'shared/types'

export const MartialStatusList = [
  {id: MaritalStatus.NOT_MARRIED, label: 'Not married'},
  {id: MaritalStatus.DIVORCED, label: 'Divorced'},
  {id: MaritalStatus.WIDOWED, label: 'Widowed'}
]

export const CountChildrenList = [
  {id: ChildrenCount.NO_CHILDREN, label: 'No children'},
  {id: ChildrenCount.ONE, label: '1'},
  {id: ChildrenCount.TWO, label: '2'},
  {id: ChildrenCount.THREE, label: '3'},
  {id: ChildrenCount.FOUR, label: '4'},
  {id: ChildrenCount.FIVE_OF_MORE, label: '5 or more'}
]

export const EducationList = [
  {id: Education.HIGH_SCHOOL, label: 'High school'},
  {id: Education.HIGH_SCHOOL_NOT_COMPLETED, label: 'High school not completed'},
  {id: Education.COLLEGE, label: 'College'},
  {id: Education.COLLEGE_NOT_COMPLETED, label: 'College not completed'},
  {id: Education.UNIVERSITY, label: 'University degree'}
]

export const CountChildrenObj: Record<ChildrenCount, string> = CountChildrenList.reduce(
  (acc, {id, label}) => ({...acc, [id]: label}),
  {} as Record<ChildrenCount, string>
)

export const EducationMap: Record<Education, string> = EducationList.reduce(
  (acc, {id, label}) => ({...acc, [id]: label}),
  {} as Record<Education, string>
)

export const SmokingList = [
  {id: Smoking.NON_SMOKER, label: 'Non smoker'},
  {id: Smoking.SOCIALLY, label: 'Socially'},
  {id: Smoking.SMOKER, label: 'Smoker'}
]

export const DrinkingList = [
  {id: Drinking.TEETOTAL, label: 'Teetotal'},
  {id: Drinking.SOCIALLY, label: 'Socially'},
  {id: Drinking.OCCASIONALLY, label: 'Occasionally'},
  {id: Drinking.OFTEN, label: 'Often'}
]
