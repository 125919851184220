import {FC} from 'react'
import {Cross as CrossIcon} from 'shared/ui/icons'
import styles from './styles.module.scss'

export type CrossProps = {
  onClose: () => void
  className?: string
}

export const Cross: FC<CrossProps> = ({onClose, className}) => (
  <div className={`${styles.crossIconWrapper} ${className}`} onClick={onClose}>
    <div className={styles.crossIcon}>{<CrossIcon />}</div>
  </div>
)
