import {ReactElement} from 'react'
import {ActiveFavorite, ActiveLike, Bullseye, Compass, HeartHalfStroke} from 'shared/ui/icons'
import {NeutralNotificationTypes, NotificationKeysTypes, NotificationTypes} from './types'

export const NotificationTypesList: {id: NotificationKeysTypes; label: string; value: NotificationTypes[]}[] = [
  {id: 'all', label: 'All notifications', value: []},
  {id: NotificationTypes.VISIT, label: 'Visitors', value: [NotificationTypes.VISIT]},
  {id: 'allMatch', label: 'Matches', value: [NotificationTypes.MATCH, NotificationTypes.POSSIBLE_MATCH]},
  {id: NotificationTypes.LIKE, label: 'Likes', value: [NotificationTypes.LIKE]},
  {id: NotificationTypes.FAVORITE, label: 'Favorites', value: [NotificationTypes.FAVORITE]}
]

export const NotificationAvatarBadgeList: Record<NotificationTypes, ReactElement> = {
  [NotificationTypes.VISIT]: <Compass />,
  [NotificationTypes.MATCH]: <HeartHalfStroke />,
  [NotificationTypes.LIKE]: <ActiveLike />,
  [NotificationTypes.FAVORITE]: <ActiveFavorite />,
  [NotificationTypes.POSSIBLE_MATCH]: <Bullseye />
}

export const NotificationMessageTextList = {
  [NotificationTypes.VISIT]: 'visited your profile',
  [NotificationTypes.MATCH]: 'and you match each other',
  [NotificationTypes.LIKE]: 'like your profile',
  [NotificationTypes.FAVORITE]: 'added you as a favorite',
  [NotificationTypes.POSSIBLE_MATCH]: 'possible match!'
}

export const Header = {
  [NotificationTypes.VISIT]: 'Just visited your profile',
  [NotificationTypes.LIKE]: 'New like from',
  [NotificationTypes.FAVORITE]: 'Added you as a favorite',
  [NeutralNotificationTypes.ONLINE]: 'Online now',
  [NeutralNotificationTypes.PHOTO_UPLOADED]: 'Just uploaded a new photo',
  [NotificationTypes.POSSIBLE_MATCH]: 'Possible match',
  [NotificationTypes.MATCH]: ''
}

export const Text = {
  [NotificationTypes.VISIT]: 'Start a chat with %Name%',
  [NotificationTypes.POSSIBLE_MATCH]: 'Start a chat with %Name%',
  [NotificationTypes.LIKE]: 'Give a like in return',
  [NotificationTypes.FAVORITE]: "Go to %Name%'s profile",
  [NeutralNotificationTypes.ONLINE]: "Go to %Name%'s profile",
  [NeutralNotificationTypes.PHOTO_UPLOADED]: "Go to %Name%'s profile",
  [NotificationTypes.MATCH]: ''
}

export const TextButton = {
  [NotificationTypes.VISIT]: 'Visit profile',
  [NotificationTypes.POSSIBLE_MATCH]: 'Visit profile',
  [NotificationTypes.LIKE]: 'Visit profile',
  [NotificationTypes.FAVORITE]: 'Visit profile',
  [NeutralNotificationTypes.ONLINE]: 'Visit profile',
  [NeutralNotificationTypes.PHOTO_UPLOADED]: 'Visit profile',
  [NotificationTypes.MATCH]: ''
}
export enum SearchFavoritesTabKeys {
  ALL = 'all',
  ADMIRERS = 'admirers',
  FOLLOWINGS = 'followings'
}

export type TabItem = {
  id: SearchFavoritesTabKeys
  label: string
}

export const tabItems: Record<SearchFavoritesTabKeys, TabItem> = {
  [SearchFavoritesTabKeys.ALL]: {
    id: SearchFavoritesTabKeys.ALL,
    label: 'All favorites'
  },
  [SearchFavoritesTabKeys.ADMIRERS]: {
    id: SearchFavoritesTabKeys.ADMIRERS,
    label: 'My admirers'
  },
  [SearchFavoritesTabKeys.FOLLOWINGS]: {
    id: SearchFavoritesTabKeys.FOLLOWINGS,
    label: 'Following'
  }
}

export const tabList = Object.values(tabItems).sort((a, b) => Number(a.id) - Number(b.id))
