import {Axios} from '@asocial/fe-utils'
import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {PhotoType} from 'entities/messages'
import {CursorType, ErrorResponsesType} from 'shared/types'
import {ProfileMediaTabs} from '../type'

export type ProfileMediaModelProps = {
  filter: ProfileMediaTabs
  cursor: CursorType
  media: {all: PhotoType[]; private: PhotoType[]; public: PhotoType[]}
  likePhotos: Record<string, boolean>
  isMediaLoaded: boolean
}

const initialState: ProfileMediaModelProps = {
  filter: 'all',
  cursor: '',
  media: {all: [], private: [], public: []},
  likePhotos: {},
  isMediaLoaded: false
}

export const profileMediaModel = createSlice({
  name: 'profileMedia',
  initialState,
  reducers: {
    setProfileMedia: (state, {payload}: PayloadAction<PhotoType[]>) => {
      state.media.all.push(...payload)
      state.media.private.push(...payload.filter((photo) => photo.type === 'private'))
      state.media.public.push(...payload.filter((photo) => photo.type === 'public'))
    },
    setProfileMediaCursor: (state, {payload}: PayloadAction<CursorType>) => {
      state.cursor = payload
    },
    setIsMediaLoaded: (state, {payload}: PayloadAction<boolean>) => {
      state.isMediaLoaded = payload
    },
    setLikePhotos: (state, {payload}: PayloadAction<Record<string, boolean>>) => {
      state.likePhotos = {...state.likePhotos, ...payload}
    },
    setLikePhoto: (state, {payload}: PayloadAction<{idPhoto: string; value: boolean}>) => {
      state.likePhotos[payload.idPhoto] = payload.value
    },
    clearStateProfileMedia: () => initialState
  }
})

export const {clearStateProfileMedia, setLikePhotos, setLikePhoto, setIsMediaLoaded} = profileMediaModel.actions

export type GetProfileMediaResponse = AxiosResponse<{
  cursor: string
  data: PhotoType[]
}>

export const GetProfileMedia = (idUser: string, dispatch: Dispatch, cursor: CursorType) =>
  useQuery<
    GetProfileMediaResponse,
    ErrorResponsesType,
    GetProfileMediaResponse,
    [string, CursorType, string, Dispatch]
  >({
    queryKey: ['getProfileMedia', cursor, idUser, dispatch],
    queryFn: async ({signal, queryKey: [_, cursor]}) => {
      try {
        const {data} = await Axios.get(`users/${idUser}/photos`, {
          params: {cursor: cursor || null, types: 'public,private'},
          signal
        })

        dispatch(profileMediaModel.actions.setProfileMedia(data.data))
        dispatch(profileMediaModel.actions.setProfileMediaCursor(data.cursor))

        return data
      } catch (error) {
        return console.error(error)
      } finally {
        dispatch(profileMediaModel.actions.setIsMediaLoaded(true))
      }
    },
    refetchOnWindowFocus: false,
    enabled: false
  })

export const useProfileMedia = () => useSelector((state: RootState) => state.profileMedia.media)

export const useProfileMediaCursor = () => useSelector((state: RootState) => state.profileMedia.cursor)

export const useProfileMediaLikePhotos = () => useSelector((state: RootState) => state.profileMedia.likePhotos)

export const useProfileMediaIsLoaded = () => useSelector((state: RootState) => state.profileMedia.isMediaLoaded)
