import MenuItem from '@mui/material/MenuItem'
import {SelectProps as MuiSelectProps} from '@mui/material/Select/Select'
import {FC} from 'react'
import {countriesList} from 'shared/lib'
import {SelectSearch} from 'shared/ui/selectSearch'

export const LocationSelect: FC<MuiSelectProps> = ({value, ...rest}) => (
  <SelectSearch
    sizePopup='xl'
    displayEmpty
    placeholder='All countries'
    options={countriesList}
    renderOptions={(option) => (
      <MenuItem key={option.id} value={option.id}>
        <img
          loading='lazy'
          width='20'
          className='mr-8'
          src={`https://flagcdn.com/w20/${option.id.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${option.id.toLowerCase()}.png 2x`}
          alt='flag'
        />
        <span className='ellipsis'>{option.label}</span>
      </MenuItem>
    )}
    filter={(option, searchValue) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase()) || option.id === value
    }
    value={value}
    {...rest}
  />
)
