import {Axios} from '@asocial/fe-utils'
import {createSelector, createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {ErrorResponsesType} from 'shared/types'

type ChatPairModelProps = {
  canViewPrivatePhotos: boolean
  canOrderRealGift: boolean
  connection: {
    likeTime: string | null
    favoriteTime: string | null
    blockTime: string | null
    blockMeTime: string | null
  }
}

const initialState: ChatPairModelProps = {
  canViewPrivatePhotos: false,
  canOrderRealGift: false,
  connection: {
    likeTime: null,
    favoriteTime: null,
    blockTime: null,
    blockMeTime: null
  }
}

export const chatPairModel = createSlice({
  name: 'chatPair',
  initialState,
  reducers: {
    setData: (state, {payload}: PayloadAction<ChatPairModelProps>) => {
      state.connection = payload.connection
      state.canViewPrivatePhotos = payload.canViewPrivatePhotos
      state.canOrderRealGift = payload.canOrderRealGift
    },
    setBlock: (state, {payload}: PayloadAction<boolean>) => {
      state.connection.blockTime = payload ? new Date().toISOString() : null
    },
    openPrivatePhoto: (state) => {
      state.canViewPrivatePhotos = true
    },
    clearPair: () => initialState
  }
})
export const {setBlock, clearPair, openPrivatePhoto} = chatPairModel.actions

export const getChatPairHandler = (idUser: string, dispatch: Dispatch, signal?: AbortSignal) =>
  Axios.get(`/pair/${idUser}`, {signal})
    .then(({data: response}) => {
      dispatch(clearPair())
      dispatch(chatPairModel.actions.setData(response))
      return response
    })
    .catch((e) => {
      console.error(e)
      e.error === 'user_blocked' && dispatch(setBlock(true))
    })

export const getChatPair = (idUser: string, dispatch: Dispatch) =>
  useQuery<AxiosResponse<ChatPairModelProps>, ErrorResponsesType>({
    queryKey: ['getChatPair', idUser],
    queryFn: ({signal}) => getChatPairHandler(idUser, dispatch, signal),
    refetchOnWindowFocus: false
  })

export const ChatActivitySetBlock = (idUser: string, isBlock: boolean, dispatch: Dispatch) =>
  useQuery<null, ErrorResponsesType>({
    queryKey: ['chatActivitySetBlock', idUser, isBlock],
    queryFn: ({signal}) =>
      Axios[isBlock ? 'post' : 'delete'](`/activity/block/${idUser}`, {signal}).then(({data}) => {
        dispatch(chatPairModel.actions.setBlock(isBlock))
        return data
      }),
    enabled: false,
    refetchOnWindowFocus: false
  })

export const chatActivitySetAbuseQueryKey = ['chatActivitySetAbuse']

export const ChatActivitySetAbuse = (idUser: string, reason: string) =>
  useQuery<null, ErrorResponsesType>({
    queryKey: chatActivitySetAbuseQueryKey,
    queryFn: () => Axios.post(`/activity/abuse/${idUser}`, {reason}),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })

export const useChatPairConnection = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.chatPair.connection,
      (connection) => ({
        isLike: Boolean(connection?.likeTime),
        isFavorite: Boolean(connection?.favoriteTime),
        isBlock: Boolean(connection?.blockTime),
        isBlockMe: Boolean(connection?.blockMeTime)
      })
    )
  )

export const useChatCanViewPrivatePhotos = () => useSelector((state: RootState) => state.chatPair.canViewPrivatePhotos)

export const useCanOrderRealGift = () => useSelector((state: RootState) => state.chatPair.canOrderRealGift)
