import {Axios} from '@asocial/fe-utils'
import {createSelector, createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {retryInsufficientFunds} from 'shared/api/retry'
import {isNextDate} from 'shared/lib'
import {sentryTrackError} from 'shared/lib/sentry'
import {CursorType, ErrorResponsesType} from 'shared/types'
import {SendMessageRequest} from '../api'
import {messageTypes} from '../config'
import {ImageType, StreamType, ChatUserType, SendMessageRequestType, VirtualGiftType} from '../types'

export type ContentPurchased = {
  purchased: boolean | null
  price: number | null
}

type ContentDisappearing = {
  dateExpired: string
  dateViewed?: string
}

export type ContentTextType = {text: string}

export type ContentPhotoType = {photo: ImageType; text?: string} & ContentPurchased

export type ContentVideoType = {video: StreamType; text?: string} & ContentPurchased

export type ContentDisappearingPhotoType = {photo?: ImageType; text?: string} & ContentPurchased & ContentDisappearing

export type ContentDisappearingVideoType = {video?: StreamType; text?: string} & ContentPurchased & ContentDisappearing

export type ContentStickerType = {
  sticker: {
    id: number
    data: {
      url: string
    }
  }
}

export type ContentVirtualGiftType = {
  virtualGift: VirtualGiftType
  text?: string
  gradient?: string
}

export type ContentAudioType = {url: string; duration: number; text?: string} & ContentPurchased
export type ContentLetterType = {idLetter: string; text: string; attachmentCounts: number}

export type DeletedContentType = {dateDeleted: string}

export type MessageType = {
  id: string
  type: messageTypes
  dateCreated: string
  from: ChatUserType
  to: ChatUserType
  content:
    | ContentTextType
    | ContentPhotoType
    | ContentStickerType
    | ContentVirtualGiftType
    | ContentDisappearingPhotoType
    | ContentVideoType
    | ContentDisappearingVideoType
    | ContentAudioType
    | ContentLetterType
    | DeletedContentType
}

export type FlirtcastResponseType = {
  cursor: CursorType
  data: Array<MessageType>
}

export type GroupedMessagesType = {
  fromId?: string
  messages: MessageType[]
  date?: string
  isNew?: boolean
}

export type LoadingMediaType = {
  cfId: string
  previewUrl?: string
  duration?: number
  type: 'photo' | 'video' | 'audio'
}

export type LoadingMessageType = {id: number; content: ContentTextType}

export type MessagesModelType = {
  cursor: CursorType
  originalList: MessageType[]
  list: GroupedMessagesType[]
  userId: string
  loadingMedia: LoadingMediaType[]
  loadingMessage: LoadingMessageType[]
  isUpdateAccount: boolean
  isAudioRecording: boolean
}

const initialState: MessagesModelType = {
  cursor: '',
  originalList: [],
  list: [],
  userId: '',
  loadingMedia: [],
  loadingMessage: [],
  isUpdateAccount: false,
  isAudioRecording: false
}

export const messagesModel = createSlice({
  name: 'messagesModel',
  initialState,
  reducers: {
    addMessagesListUp: (state, {payload}: PayloadAction<MessageType[]>) => {
      for (const message of payload) {
        // Разделитель по датам
        const lastMessage = state.originalList[0]
        if (lastMessage && isNextDate(message.dateCreated, lastMessage.dateCreated)) {
          state.list.unshift({date: lastMessage.dateCreated, messages: []})
        }

        // Группировка сообщений
        const lastMessageList = state.list[0]
        if (!lastMessageList || lastMessageList.date || lastMessageList.fromId !== message.from.id) {
          state.list.unshift({fromId: message.from.id, messages: [message]})
        } else {
          lastMessageList?.messages?.unshift(message)
        }

        state.originalList.unshift(message)
      }
    },
    addMessageListDown: (
      state,
      {
        payload: {messages, idLastReadMessage, isBottom}
      }: PayloadAction<{messages: MessageType | MessageType[]; idLastReadMessage?: string | null; isBottom?: boolean}>
    ) => {
      if (!Array.isArray(messages)) messages = [messages]

      let isNextMessageNew = idLastReadMessage === null

      for (const message of messages) {
        // Проверка, что мы находимся в нужном чате
        if (state.userId !== message.from.id && state.userId !== message.to.id) return

        // Проверка на дубликат
        if (state.originalList.find((item) => item.id === message.id)) return

        // Разделитель по датам
        const lastMessage = state.originalList[state.originalList.length - 1]
        if (lastMessage && isNextDate(message.dateCreated, lastMessage.dateCreated)) {
          state.list.push({date: message.dateCreated, messages: []})
        }

        // Разделитель New messages
        if ((isBottom === false || isNextMessageNew) && state.userId === message.from.id) {
          !state.list.find((item) => item.isNew) && state.list.push({isNew: true, messages: []})
          isNextMessageNew = false
        }

        if (message.id === idLastReadMessage) {
          isNextMessageNew = true
        }

        // Группировка сообщений
        const lastMessageList = state.list[state.list.length - 1]
        if (!lastMessageList || lastMessageList.date || lastMessageList.fromId !== message.from.id) {
          state.list.push({fromId: message.from.id, messages: [message]})
        } else {
          lastMessageList?.messages?.push(message)
        }

        state.originalList.push(message)
      }
    },
    addMessagesDividerUp: (state) => {
      const lastMessage = state.originalList[0]
      if (lastMessage) {
        state.list.unshift({date: lastMessage.dateCreated, messages: []})
      }
    },
    deleteDividerIsNew: (state) => {
      state.list = state.list.filter((item) => !item.isNew)
    },
    setCursor: (state, {payload}: PayloadAction<CursorType>) => {
      state.cursor = payload
    },
    setUserId: (state, {payload}: PayloadAction<string>) => {
      state.userId = payload
    },
    addLoadingMedia: (state, {payload}: PayloadAction<LoadingMediaType>) => {
      state.loadingMedia.push(payload)
    },
    deleteLoadingMedia: (state, {payload: cfId}: PayloadAction<string>) => {
      state.loadingMedia = state.loadingMedia.filter((item) => item.cfId !== cfId)
    },
    addLoadingMessage: (state, {payload}: PayloadAction<LoadingMessageType>) => {
      state.loadingMessage.push(payload)
    },
    deleteLoadingMessage: (state, {payload: id}: PayloadAction<number>) => {
      state.loadingMessage = state.loadingMessage.filter((item) => item.id !== id)
    },
    setPurchaseMessage: (state, {payload: newMessage}: PayloadAction<MessageType>) => {
      const message = state.list
        .reduce((prev, curr) => [...prev, ...curr.messages], [] as MessageType[])
        .find((item) => item.id === newMessage.id)

      if (!message) return
      ;(message.content as ContentPurchased).purchased = true

      const contentDisappearing = newMessage.content as ContentDisappearing
      if (contentDisappearing.dateExpired) {
        contentDisappearing.dateExpired = (newMessage.content as ContentDisappearing).dateExpired
      }
    },
    clearMessagesState: () => initialState,
    setIsUpdateAccount: (state, {payload}: PayloadAction<boolean>) => {
      state.isUpdateAccount = payload
    },
    updateAudioRecording: (state, {payload}: PayloadAction<boolean>) => {
      state.isAudioRecording = payload
    }
  }
})

export const {
  addMessageListDown,
  setCursor,
  clearMessagesState,
  addLoadingMedia,
  deleteLoadingMedia,
  setPurchaseMessage,
  addLoadingMessage,
  deleteLoadingMessage,
  deleteDividerIsNew,
  setIsUpdateAccount,
  updateAudioRecording
} = messagesModel.actions

type GetDialogsResponse = {
  cursor: string
  data: MessageType[]
}

export const GetMessages = (idUser: string, cursor: CursorType, idLastReadMessage: string | null, dispatch: Dispatch) =>
  useQuery<AxiosResponse<GetDialogsResponse>, ErrorResponsesType>({
    queryKey: ['getMessages', idUser, cursor, idLastReadMessage],
    queryFn: ({signal}) =>
      Axios.get(`chat/dialogs/${idUser}/messages`, {params: {cursor: cursor || null}, signal}).then(
        ({data: response}) => {
          dispatch(messagesModel.actions.setUserId(idUser))
          cursor === '' ?
            dispatch(addMessageListDown({messages: response.data.reverse(), idLastReadMessage}))
          : dispatch(messagesModel.actions.addMessagesListUp(response.data))
          dispatch(setCursor(response.cursor))
          // Если мы загрузили последнюю страницу, то добавляем вверх ещё разделитель
          if (response.cursor === null) {
            dispatch(messagesModel.actions.addMessagesDividerUp())
          }

          return response.data
        }
      ),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })

export const onSuccessSendMessageHandler = ({data: message}: AxiosResponse<MessageType, any>, dispatch: Dispatch) => {
  dispatch(addMessageListDown({messages: message}))
  dispatch(deleteDividerIsNew())
}

export const SendMessage = (idUser: string, data: SendMessageRequestType, type: messageTypes, dispatch: Dispatch) =>
  useQuery<AxiosResponse<MessageType>, ErrorResponsesType, AxiosResponse<MessageType>, [string]>({
    queryKey: [`sendMessages/${idUser}/${type}/${JSON.stringify(data)}`],
    queryFn: () =>
      SendMessageRequest(idUser, data, type)
        .then((data) => {
          onSuccessSendMessageHandler(data, dispatch)
          return data
        })
        .catch((err) => {
          if (err.error === 'insufficient_funds') throw err
          sentryTrackError(`Error with chat: ${JSON.stringify(err)}`)
          throw err
        }),
    enabled: false,
    retry: retryInsufficientFunds,
    refetchOnWindowFocus: false
  })

export const useMessagesList = () => useSelector((state: RootState) => state.messages.list)

export const useMessagesCursor = () => useSelector((state: RootState) => state.messages.cursor)

export const useMessagesLoadingMedia = () => useSelector((state: RootState) => state.messages.loadingMedia)

export const useMessagesLoadingMessage = () => useSelector((state: RootState) => state.messages.loadingMessage)

export const useMessagesIsUpdateAccount = () => useSelector((state: RootState) => state.messages.isUpdateAccount)

export const useIsMessageToEachOther = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.messages.originalList,
      (state: RootState) => state.messages.userId,
      (list, userId) => !!list.find((item) => item.from.id === userId) && !!list.find((item) => item.from.id !== userId)
    )
  )

export const useMessagesIsAudioRecording = () => useSelector((state: RootState) => state.messages.isAudioRecording)
