import {Axios} from '@asocial/fe-utils'
import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {keepPreviousData, useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {ErrorResponsesType} from 'shared/types'
import {VirtualGiftType} from '../types'

export type VirtualGiftCategoryType = {
  name: string
  virtualGifts: VirtualGiftType[]
}

type RandomVirtualGiftsResponse = {
  expensive: VirtualGiftType[]
  middle: VirtualGiftType[]
  low: VirtualGiftType[]
}

export type VirtualGiftsModelType = {
  isOpen: boolean
  data: VirtualGiftCategoryType[]
  selectVirtualGift?: VirtualGiftType
}

const initialState: VirtualGiftsModelType = {
  isOpen: false,
  data: [],
  selectVirtualGift: undefined
}

export const virtualGiftsModel = createSlice({
  name: 'virtualGiftsModel',
  initialState,
  reducers: {
    virtualGiftsSetData: (state, {payload}: PayloadAction<VirtualGiftCategoryType[]>) => {
      state.data = payload
    },
    virtualGiftsOpenModal: (state) => {
      state.isOpen = true
    },
    virtualGiftsCloseModal: (state) => {
      state.isOpen = false
      state.selectVirtualGift = undefined
    },
    virtualGiftsSelectVirtualGift: (state, {payload}: PayloadAction<VirtualGiftType | undefined>) => {
      state.selectVirtualGift = payload
    }
  }
})

export const {virtualGiftsOpenModal, virtualGiftsCloseModal, virtualGiftsSelectVirtualGift} = virtualGiftsModel.actions

export const GetVirtualGifts = (dispatch: Dispatch) =>
  useQuery<AxiosResponse<{data: VirtualGiftCategoryType[]}>, ErrorResponsesType>({
    queryKey: ['getVirtualGifts'],
    queryFn: ({signal}) =>
      Axios.get(`chat/virtual-gifts`, {signal}).then(({data: response}) => {
        dispatch(virtualGiftsModel.actions.virtualGiftsSetData(response.data))
        return response
      }),
    enabled: false,
    refetchOnWindowFocus: false
  })

export const GetRandomVirtualGifts = () =>
  useQuery<AxiosResponse<RandomVirtualGiftsResponse>, ErrorResponsesType>({
    queryKey: ['getRandomVirtualGifts'],
    queryFn: ({signal}) => Axios.get(`chat/virtual-gifts/random`, {signal}),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    placeholderData: keepPreviousData,
    enabled: false
  })

export const useVirtualGiftsData = () => useSelector((state: RootState) => state.virtualGifts.data)

export const useVirtualGiftsIsOpen = () => useSelector((state: RootState) => state.virtualGifts.isOpen)

export const useVirtualGiftsSelectVirtualGift = () =>
  useSelector((state: RootState) => state.virtualGifts.selectVirtualGift)

export const useHasVirtualGifts = () => useSelector((state: RootState) => !!state.virtualGifts.data.length)
