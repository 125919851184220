import {OverridableTokenClientConfig} from '@react-oauth/google'
import {createContext, FC, ReactNode, useState} from 'react'

type GoogleLoginType = (overrideConfig?: OverridableTokenClientConfig | undefined) => void

export type GoogleContextType = {
  googleLogin: GoogleLoginType
  setGoogleLogin: (func: GoogleLoginType) => void
}
export const GoogleContext = createContext<GoogleContextType | null>(null)

export const GoogleProvider: FC<{children: ReactNode}> = ({children}) => {
  const [googleLogin, setGoogleLogin] = useState<GoogleLoginType>(() => {})

  return <GoogleContext.Provider value={{googleLogin, setGoogleLogin}}>{children}</GoogleContext.Provider>
}
