import Slide, {SlideProps} from '@mui/material/Slide'
import {SnackbarProvider} from 'notistack'
import React from 'react'
import {DefaultSnackbar} from 'shared/ui/snackbar'

type TransitionProps = Omit<SlideProps, 'direction'>

function TransitionLeft(props: TransitionProps) {
  return <Slide {...props} direction='up' />
}

export const withSnackbar = (component: () => React.ReactNode) => () => (
  <SnackbarProvider
    Components={{default: DefaultSnackbar}}
    maxSnack={1}
    TransitionComponent={TransitionLeft}
    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
  >
    {component()}
  </SnackbarProvider>
)
