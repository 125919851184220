import InputAdornment from '@mui/material/InputAdornment'
import TextField, {TextFieldProps} from '@mui/material/TextField'
import {FC} from 'react'
import {Search} from 'shared/ui/icons'
import './styles.scss'

export const SearchField: FC<TextFieldProps> = (props) => (
  <TextField
    {...props}
    autoFocus
    placeholder='Search'
    className='override-selectSearch-search-field'
    fullWidth
    InputProps={{
      endAdornment: (
        <InputAdornment position='start'>
          <div className='override-selectSearch-search-icon'>
            <Search />
          </div>
        </InputAdornment>
      )
    }}
    onKeyDown={(e) => e.key !== 'Escape' && e.stopPropagation()}
  />
)
