import {Axios} from '@asocial/fe-utils'
import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {ErrorResponsesType, GetInterestsResponse, InterestCategory} from 'shared/types'

type InterestsModelProps = {
  data: InterestCategory[]
  isOpen: boolean
}

const initialState: InterestsModelProps = {
  data: [],
  isOpen: false
}

export const interestsModel = createSlice({
  name: 'interestsModel',
  initialState,
  reducers: {
    setInterests: (state, {payload}: PayloadAction<InterestCategory[]>) => {
      state.data = payload
    },
    interestsOpenModal: (state) => {
      state.isOpen = true
    },
    interestsCloseModal: (state) => {
      state.isOpen = false
    }
  }
})

export const {interestsOpenModal, interestsCloseModal} = interestsModel.actions

export const GetInterests = (dispatch: Dispatch) =>
  useQuery<AxiosResponse<GetInterestsResponse, ErrorResponsesType>, ErrorResponsesType>({
    queryKey: ['getInterests'],
    queryFn: () =>
      Axios.get('interests').then(({data: response}) => {
        dispatch(interestsModel.actions.setInterests(response.data || []))
        return response
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })

export const useInterestsData = () => useSelector((state: RootState) => state.interests.data)

export const useInterestsIsOpen = () => useSelector((state: RootState) => state.interests.isOpen)
