import moment from 'moment/moment'
import {Gender} from '../types'
import {CountryKeys} from './countryISOalpha'

export enum GOOGLE_TRACKING_EVENTS {
  SIGN_UP = 'sign_up',
  CARD_SUBMIT = 'card_submit',
  FIRST_PURCHASE = 'purchase',
  REPEATED_PURCHASE = 'repeated_purchase',
  FIRST_CREDIT_SPEND = 'first_credit_spend',
  PUSH_SUBSCRIPTION = 'push_subscription',
  EMAIL_CONFIRM = 'email_confirm'
}

const googleCountryTargetAudience: CountryKeys[] = [
  'US',
  'CA',
  'GB',
  'AU',
  'NZ',
  'DK',
  'LU',
  'AT',
  'BE',
  'NL',
  'IT',
  'FR',
  'FI',
  'IE',
  'IS',
  'SE',
  'CH',
  'NO',
  'DE'
]

type trackGoogleEventParams = {
  event: GOOGLE_TRACKING_EVENTS
  value?: string | number
} & isGoogleTargetAudienceParams

export const trackGoogleEvent = ({event, value}: trackGoogleEventParams) => {
  if (!window.dataLayer) return

  if (!value) return window.dataLayer.push({event: event})

  window.dataLayer.push({event, value})
}

type isGoogleTargetAudienceParams = {
  dateBirth: string
  gender: Gender
  country: CountryKeys | string
}

//TODO delete??
const isGoogleTargetAudience = ({dateBirth, gender, country}: isGoogleTargetAudienceParams) =>
  gender === Gender.MALE &&
  moment().diff(dateBirth, 'years') >= 35 &&
  googleCountryTargetAudience.includes(country as CountryKeys)
