export type CountryKeys =
  | 'AD'
  | 'AE'
  | 'AF'
  | 'AG'
  | 'AI'
  | 'AL'
  | 'AM'
  | 'AO'
  | 'AQ'
  | 'AR'
  | 'AS'
  | 'AT'
  | 'AU'
  | 'AW'
  | 'AX'
  | 'AZ'
  | 'BA'
  | 'BB'
  | 'BD'
  | 'BE'
  | 'BF'
  | 'BG'
  | 'BH'
  | 'BI'
  | 'BJ'
  | 'BL'
  | 'BM'
  | 'BN'
  | 'BO'
  | 'BR'
  | 'BS'
  | 'BT'
  | 'BV'
  | 'BW'
  | 'BY'
  | 'BZ'
  | 'CA'
  | 'CC'
  | 'CD'
  | 'CF'
  | 'CG'
  | 'CH'
  | 'CI'
  | 'CK'
  | 'CL'
  | 'CM'
  | 'CN'
  | 'CO'
  | 'CR'
  | 'CU'
  | 'CV'
  | 'CW'
  | 'CX'
  | 'CY'
  | 'CZ'
  | 'DE'
  | 'DJ'
  | 'DK'
  | 'DM'
  | 'DO'
  | 'DZ'
  | 'EC'
  | 'EE'
  | 'EG'
  | 'EH'
  | 'ER'
  | 'ES'
  | 'ET'
  | 'FI'
  | 'FJ'
  | 'FK'
  | 'FM'
  | 'FO'
  | 'FR'
  | 'GA'
  | 'GB'
  | 'GD'
  | 'GE'
  | 'GF'
  | 'GG'
  | 'GH'
  | 'GI'
  | 'GL'
  | 'GM'
  | 'GN'
  | 'GP'
  | 'GQ'
  | 'GR'
  | 'GS'
  | 'GT'
  | 'GU'
  | 'GW'
  | 'GY'
  | 'HK'
  | 'HM'
  | 'HN'
  | 'HR'
  | 'HT'
  | 'HU'
  | 'ID'
  | 'IE'
  | 'IL'
  | 'IM'
  | 'IN'
  | 'IO'
  | 'IQ'
  | 'IR'
  | 'IS'
  | 'IT'
  | 'JE'
  | 'JM'
  | 'JO'
  | 'JP'
  | 'KE'
  | 'KG'
  | 'KH'
  | 'KI'
  | 'KM'
  | 'KN'
  | 'KP'
  | 'KR'
  | 'KW'
  | 'KY'
  | 'KZ'
  | 'LA'
  | 'LB'
  | 'LC'
  | 'LI'
  | 'LK'
  | 'LR'
  | 'LS'
  | 'LT'
  | 'LU'
  | 'LV'
  | 'LY'
  | 'MA'
  | 'MC'
  | 'MD'
  | 'ME'
  | 'MF'
  | 'MG'
  | 'MH'
  | 'MK'
  | 'ML'
  | 'MM'
  | 'MN'
  | 'MO'
  | 'MP'
  | 'MQ'
  | 'MR'
  | 'MS'
  | 'MT'
  | 'MU'
  | 'MV'
  | 'MW'
  | 'MX'
  | 'MY'
  | 'MZ'
  | 'NA'
  | 'NC'
  | 'NE'
  | 'NF'
  | 'NG'
  | 'NI'
  | 'NL'
  | 'NO'
  | 'NP'
  | 'NR'
  | 'NU'
  | 'NZ'
  | 'OM'
  | 'PA'
  | 'PE'
  | 'PF'
  | 'PG'
  | 'PH'
  | 'PK'
  | 'PL'
  | 'PM'
  | 'PN'
  | 'PR'
  | 'PS'
  | 'PT'
  | 'PW'
  | 'PY'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RS'
  | 'RU'
  | 'RW'
  | 'SA'
  | 'SB'
  | 'SC'
  | 'SD'
  | 'SE'
  | 'SG'
  | 'SH'
  | 'SI'
  | 'SJ'
  | 'SK'
  | 'SL'
  | 'SM'
  | 'SN'
  | 'SO'
  | 'SR'
  | 'SS'
  | 'ST'
  | 'SV'
  | 'SX'
  | 'SY'
  | 'SZ'
  | 'TC'
  | 'TD'
  | 'TF'
  | 'TG'
  | 'TH'
  | 'TJ'
  | 'TK'
  | 'TL'
  | 'TM'
  | 'TN'
  | 'TO'
  | 'TR'
  | 'TT'
  | 'TV'
  | 'TW'
  | 'TZ'
  | 'UA'
  | 'UG'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VA'
  | 'VC'
  | 'VE'
  | 'VG'
  | 'VI'
  | 'VN'
  | 'VU'
  | 'WF'
  | 'WS'
  | 'XK'
  | 'YE'
  | 'YT'
  | 'ZA'
  | 'ZM'
  | 'ZW'

export type CountryType = {
  id: CountryKeys
  label: string
}

export const countriesList: CountryType[] = [
  {id: 'AD', label: 'Andorra'},
  {id: 'AE', label: 'United Arab Emirates'},
  {id: 'AF', label: 'Afghanistan'},
  {id: 'AG', label: 'Antigua and Barbuda'},
  {id: 'AI', label: 'Anguilla'},
  {id: 'AL', label: 'Albania'},
  {id: 'AM', label: 'Armenia'},
  {id: 'AO', label: 'Angola'},
  {id: 'AQ', label: 'Antarctica'},
  {id: 'AR', label: 'Argentina'},
  {id: 'AS', label: 'American Samoa'},
  {id: 'AT', label: 'Austria'},
  {id: 'AU', label: 'Australia'},
  {id: 'AW', label: 'Aruba'},
  {id: 'AX', label: 'Alland Islands'},
  {id: 'AZ', label: 'Azerbaijan'},
  {id: 'BA', label: 'Bosnia and Herzegovina'},
  {id: 'BB', label: 'Barbados'},
  {id: 'BD', label: 'Bangladesh'},
  {id: 'BE', label: 'Belgium'},
  {id: 'BF', label: 'Burkina Faso'},
  {id: 'BG', label: 'Bulgaria'},
  {id: 'BH', label: 'Bahrain'},
  {id: 'BI', label: 'Burundi'},
  {id: 'BJ', label: 'Benin'},
  {id: 'BL', label: 'Saint Barthelemy'},
  {id: 'BM', label: 'Bermuda'},
  {id: 'BN', label: 'Brunei Darussalam'},
  {id: 'BO', label: 'Bolivia'},
  {id: 'BR', label: 'Brazil'},
  {id: 'BS', label: 'Bahamas'},
  {id: 'BT', label: 'Bhutan'},
  {id: 'BV', label: 'Bouvet Island'},
  {id: 'BW', label: 'Botswana'},
  {id: 'BY', label: 'Belarus'},
  {id: 'BZ', label: 'Belize'},
  {id: 'CA', label: 'Canada'},
  {id: 'CC', label: 'Cocos (Keeling) Islands'},
  {id: 'CD', label: 'Congo, Democratic Republic of the'},
  {id: 'CF', label: 'Central African Republic'},
  {id: 'CG', label: 'Congo, Republic of the'},
  {id: 'CH', label: 'Switzerland'},
  {id: 'CI', label: "Cote d'Ivoire"},
  {id: 'CK', label: 'Cook Islands'},
  {id: 'CL', label: 'Chile'},
  {id: 'CM', label: 'Cameroon'},
  {id: 'CN', label: 'China'},
  {id: 'CO', label: 'Colombia'},
  {id: 'CR', label: 'Costa Rica'},
  {id: 'CU', label: 'Cuba'},
  {id: 'CV', label: 'Cape Verde'},
  {id: 'CW', label: 'Curacao'},
  {id: 'CX', label: 'Christmas Island'},
  {id: 'CY', label: 'Cyprus'},
  {id: 'CZ', label: 'Czech Republic'},
  {id: 'DE', label: 'Germany'},
  {id: 'DJ', label: 'Djibouti'},
  {id: 'DK', label: 'Denmark'},
  {id: 'DM', label: 'Dominica'},
  {id: 'DO', label: 'Dominican Republic'},
  {id: 'DZ', label: 'Algeria'},
  {id: 'EC', label: 'Ecuador'},
  {id: 'EE', label: 'Estonia'},
  {id: 'EG', label: 'Egypt'},
  {id: 'EH', label: 'Western Sahara'},
  {id: 'ER', label: 'Eritrea'},
  {id: 'ES', label: 'Spain'},
  {id: 'ET', label: 'Ethiopia'},
  {id: 'FI', label: 'Finland'},
  {id: 'FJ', label: 'Fiji'},
  {id: 'FK', label: 'Falkland Islands (Malvinas)'},
  {id: 'FM', label: 'Micronesia, Federated States of'},
  {id: 'FO', label: 'Faroe Islands'},
  {id: 'FR', label: 'France'},
  {id: 'GA', label: 'Gabon'},
  {id: 'GB', label: 'United Kingdom'},
  {id: 'GD', label: 'Grenada'},
  {id: 'GE', label: 'Georgia'},
  {id: 'GF', label: 'French Guiana'},
  {id: 'GG', label: 'Guernsey'},
  {id: 'GH', label: 'Ghana'},
  {id: 'GI', label: 'Gibraltar'},
  {id: 'GL', label: 'Greenland'},
  {id: 'GM', label: 'Gambia'},
  {id: 'GN', label: 'Guinea'},
  {id: 'GP', label: 'Guadeloupe'},
  {id: 'GQ', label: 'Equatorial Guinea'},
  {id: 'GR', label: 'Greece'},
  {id: 'GS', label: 'South Georgia and the South Sandwich Islands'},
  {id: 'GT', label: 'Guatemala'},
  {id: 'GU', label: 'Guam'},
  {id: 'GW', label: 'Guinea-Bissau'},
  {id: 'GY', label: 'Guyana'},
  {id: 'HK', label: 'Hong Kong'},
  {id: 'HM', label: 'Heard Island and McDonald Islands'},
  {id: 'HN', label: 'Honduras'},
  {id: 'HR', label: 'Croatia'},
  {id: 'HT', label: 'Haiti'},
  {id: 'HU', label: 'Hungary'},
  {id: 'ID', label: 'Indonesia'},
  {id: 'IE', label: 'Ireland'},
  {id: 'IL', label: 'Israel'},
  {id: 'IM', label: 'Isle of Man'},
  {id: 'IN', label: 'India'},
  {id: 'IO', label: 'British Indian Ocean Territory'},
  {id: 'IQ', label: 'Iraq'},
  {id: 'IR', label: 'Iran, Islamic Republic of'},
  {id: 'IS', label: 'Iceland'},
  {id: 'IT', label: 'Italy'},
  {id: 'JE', label: 'Jersey'},
  {id: 'JM', label: 'Jamaica'},
  {id: 'JO', label: 'Jordan'},
  {id: 'JP', label: 'Japan'},
  {id: 'KE', label: 'Kenya'},
  {id: 'KG', label: 'Kyrgyzstan'},
  {id: 'KH', label: 'Cambodia'},
  {id: 'KI', label: 'Kiribati'},
  {id: 'KM', label: 'Comoros'},
  {id: 'KN', label: 'Saint Kitts and Nevis'},
  {id: 'KP', label: "Korea, Democratic People's Republic of"},
  {id: 'KR', label: 'Korea, Republic of'},
  {id: 'KW', label: 'Kuwait'},
  {id: 'KY', label: 'Cayman Islands'},
  {id: 'KZ', label: 'Kazakhstan'},
  {id: 'LA', label: "Lao People's Democratic Republic"},
  {id: 'LB', label: 'Lebanon'},
  {id: 'LC', label: 'Saint Lucia'},
  {id: 'LI', label: 'Liechtenstein'},
  {id: 'LK', label: 'Sri Lanka'},
  {id: 'LR', label: 'Liberia'},
  {id: 'LS', label: 'Lesotho'},
  {id: 'LT', label: 'Lithuania'},
  {id: 'LU', label: 'Luxembourg'},
  {id: 'LV', label: 'Latvia'},
  {id: 'LY', label: 'Libya'},
  {id: 'MA', label: 'Morocco'},
  {id: 'MC', label: 'Monaco'},
  {id: 'MD', label: 'Moldova, Republic of'},
  {id: 'ME', label: 'Montenegro'},
  {id: 'MF', label: 'Saint Martin (French part)'},
  {id: 'MG', label: 'Madagascar'},
  {id: 'MH', label: 'Marshall Islands'},
  {id: 'MK', label: 'Macedonia, the Former Yugoslav Republic of'},
  {id: 'ML', label: 'Mali'},
  {id: 'MM', label: 'Myanmar'},
  {id: 'MN', label: 'Mongolia'},
  {id: 'MO', label: 'Macao'},
  {id: 'MP', label: 'Northern Mariana Islands'},
  {id: 'MQ', label: 'Martinique'},
  {id: 'MR', label: 'Mauritania'},
  {id: 'MS', label: 'Montserrat'},
  {id: 'MT', label: 'Malta'},
  {id: 'MU', label: 'Mauritius'},
  {id: 'MV', label: 'Maldives'},
  {id: 'MW', label: 'Malawi'},
  {id: 'MX', label: 'Mexico'},
  {id: 'MY', label: 'Malaysia'},
  {id: 'MZ', label: 'Mozambique'},
  {id: 'NA', label: 'Namibia'},
  {id: 'NC', label: 'New Caledonia'},
  {id: 'NE', label: 'Niger'},
  {id: 'NF', label: 'Norfolk Island'},
  {id: 'NG', label: 'Nigeria'},
  {id: 'NI', label: 'Nicaragua'},
  {id: 'NL', label: 'Netherlands'},
  {id: 'NO', label: 'Norway'},
  {id: 'NP', label: 'Nepal'},
  {id: 'NR', label: 'Nauru'},
  {id: 'NU', label: 'Niue'},
  {id: 'NZ', label: 'New Zealand'},
  {id: 'OM', label: 'Oman'},
  {id: 'PA', label: 'Panama'},
  {id: 'PE', label: 'Peru'},
  {id: 'PF', label: 'French Polynesia'},
  {id: 'PG', label: 'Papua New Guinea'},
  {id: 'PH', label: 'Philippines'},
  {id: 'PK', label: 'Pakistan'},
  {id: 'PL', label: 'Poland'},
  {id: 'PM', label: 'Saint Pierre and Miquelon'},
  {id: 'PN', label: 'Pitcairn'},
  {id: 'PR', label: 'Puerto Rico'},
  {id: 'PS', label: 'Palestine, State of'},
  {id: 'PT', label: 'Portugal'},
  {id: 'PW', label: 'Palau'},
  {id: 'PY', label: 'Paraguay'},
  {id: 'QA', label: 'Qatar'},
  {id: 'RE', label: 'Reunion'},
  {id: 'RO', label: 'Romania'},
  {id: 'RS', label: 'Serbia'},
  {id: 'RU', label: 'Russian Federation'},
  {id: 'RW', label: 'Rwanda'},
  {id: 'SA', label: 'Saudi Arabia'},
  {id: 'SB', label: 'Solomon Islands'},
  {id: 'SC', label: 'Seychelles'},
  {id: 'SD', label: 'Sudan'},
  {id: 'SE', label: 'Sweden'},
  {id: 'SG', label: 'Singapore'},
  {id: 'SH', label: 'Saint Helena'},
  {id: 'SI', label: 'Slovenia'},
  {id: 'SJ', label: 'Svalbard and Jan Mayen'},
  {id: 'SK', label: 'Slovakia'},
  {id: 'SL', label: 'Sierra Leone'},
  {id: 'SM', label: 'San Marino'},
  {id: 'SN', label: 'Senegal'},
  {id: 'SO', label: 'Somalia'},
  {id: 'SR', label: 'Suriname'},
  {id: 'SS', label: 'South Sudan'},
  {id: 'ST', label: 'Sao Tome and Principe'},
  {id: 'SV', label: 'El Salvador'},
  {id: 'SX', label: 'Sint Maarten (Dutch part)'},
  {id: 'SY', label: 'Syrian Arab Republic'},
  {id: 'SZ', label: 'Swaziland'},
  {id: 'TC', label: 'Turks and Caicos Islands'},
  {id: 'TD', label: 'Chad'},
  {id: 'TF', label: 'French Southern Territories'},
  {id: 'TG', label: 'Togo'},
  {id: 'TH', label: 'Thailand'},
  {id: 'TJ', label: 'Tajikistan'},
  {id: 'TK', label: 'Tokelau'},
  {id: 'TL', label: 'Timor-Leste'},
  {id: 'TM', label: 'Turkmenistan'},
  {id: 'TN', label: 'Tunisia'},
  {id: 'TO', label: 'Tonga'},
  {id: 'TR', label: 'Turkey'},
  {id: 'TT', label: 'Trinidad and Tobago'},
  {id: 'TV', label: 'Tuvalu'},
  {id: 'TW', label: 'Taiwan, Republic of China'},
  {id: 'TZ', label: 'United Republic of Tanzania'},
  {id: 'UA', label: 'Ukraine'},
  {id: 'UG', label: 'Uganda'},
  {id: 'US', label: 'United States'},
  {id: 'UY', label: 'Uruguay'},
  {id: 'UZ', label: 'Uzbekistan'},
  {id: 'VA', label: 'Holy See (Vatican City State)'},
  {id: 'VC', label: 'Saint Vincent and the Grenadines'},
  {id: 'VE', label: 'Venezuela'},
  {id: 'VG', label: 'British Virgin Islands'},
  {id: 'VI', label: 'US Virgin Islands'},
  {id: 'VN', label: 'Vietnam'},
  {id: 'VU', label: 'Vanuatu'},
  {id: 'WF', label: 'Wallis and Futuna'},
  {id: 'WS', label: 'Samoa'},
  {id: 'XK', label: 'Kosovo'},
  {id: 'YE', label: 'Yemen'},
  {id: 'YT', label: 'Mayotte'},
  {id: 'ZA', label: 'South Africa'},
  {id: 'ZM', label: 'Zambia'},
  {id: 'ZW', label: 'Zimbabwe'}
]

export const countriesMap: Record<CountryKeys, string> = {
  AD: 'Andorra',
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AO: 'Angola',
  AQ: 'Antarctica',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Alland Islands',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'Saint Barthelemy',
  BM: 'Bermuda',
  BN: 'Brunei Darussalam',
  BO: 'Bolivia',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos (Keeling) Islands',
  CD: 'Congo, Democratic Republic of the',
  CF: 'Central African Republic',
  CG: 'Congo, Republic of the',
  CH: 'Switzerland',
  CI: "Cote d'Ivoire",
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cape Verde',
  CW: 'Curacao',
  CX: 'Christmas Island',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands (Malvinas)',
  FM: 'Micronesia, Federated States of',
  FO: 'Faroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GS: 'South Georgia and the South Sandwich Islands',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'Heard Island and McDonald Islands',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'India',
  IO: 'British Indian Ocean Territory',
  IQ: 'Iraq',
  IR: 'Iran, Islamic Republic of',
  IS: 'Iceland',
  IT: 'Italy',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts and Nevis',
  KP: "Korea, Democratic People's Republic of",
  KR: 'Korea, Republic of',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: "Lao People's Democratic Republic",
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova, Republic of',
  ME: 'Montenegro',
  MF: 'Saint Martin (French part)',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'Macedonia, the Former Yugoslav Republic of',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macao',
  MP: 'Northern Mariana Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PM: 'Saint Pierre and Miquelon',
  PN: 'Pitcairn',
  PR: 'Puerto Rico',
  PS: 'Palestine, State of',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SH: 'Saint Helena',
  SI: 'Slovenia',
  SJ: 'Svalbard and Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'South Sudan',
  ST: 'Sao Tome and Principe',
  SV: 'El Salvador',
  SX: 'Sint Maarten (Dutch part)',
  SY: 'Syrian Arab Republic',
  SZ: 'Swaziland',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'Timor-Leste',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkey',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan, Republic of China',
  TZ: 'United Republic of Tanzania',
  UA: 'Ukraine',
  UG: 'Uganda',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Holy See (Vatican City State)',
  VC: 'Saint Vincent and the Grenadines',
  VE: 'Venezuela',
  VG: 'British Virgin Islands',
  VI: 'US Virgin Islands',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis and Futuna',
  WS: 'Samoa',
  XK: 'Kosovo',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe'
}

export const getCountry = (key: CountryKeys | null) => (key ? countriesMap[key] : null)

export type postalCodeCountries = 'AU' | 'CA' | 'US'

export const postalCodeRegEx: Record<postalCodeCountries, RegExp> = {
  AU: new RegExp(/^\d{4}$/),
  CA: new RegExp(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i),
  US: new RegExp(/^\d{5}(-\d{4})?$/)
}
