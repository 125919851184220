import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {OrderStatusEnum, OrderType} from 'entities/realGifts/types'
import {CursorType, ErrorResponsesType} from 'shared/types'
import {GetOrdersRequest} from '../api'

export type OrdersModelType = {
  list: OrderType[]
  cursor: CursorType
  isOrders: boolean
}

export const initialStateOrdersModel: OrdersModelType = {
  list: [],
  cursor: '',
  isOrders: false
}

export const ordersModel = createSlice({
  name: 'orders',
  initialState: initialStateOrdersModel,
  reducers: {
    ordersSetData: (state, {payload}: PayloadAction<{data: OrderType[]; cursor: CursorType}>) => {
      state.list.push(...payload.data)
      state.cursor = payload.cursor
    },
    cancelOrder: (state, {payload: idOrder}: PayloadAction<string>) => {
      state.list = state.list.map((item) => {
        if (item.id === idOrder) item.status = OrderStatusEnum.CANCELED
        return item
      })
    },
    setIsOrders: (state, {payload}: PayloadAction<boolean>) => {
      state.isOrders = payload
    },
    clearOrders: () => initialStateOrdersModel
  }
})

export const {cancelOrder, clearOrders, setIsOrders} = ordersModel.actions

export type GetOrdersRequestType = AxiosResponse<{
  data: OrderType[]
  cursor: CursorType
}>

export const GetOrders = (dispatch: Dispatch) =>
  useQuery<GetOrdersRequestType, ErrorResponsesType, GetOrdersRequestType, [string, CursorType]>({
    queryKey: ['getRealGifts', useSelector((state: RootState) => state.orders.cursor)],
    queryFn: ({signal, queryKey: [_, cursor]}) =>
      GetOrdersRequest(cursor, signal).then((response) => {
        dispatch(ordersModel.actions.ordersSetData(response.data))
        return response
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })

export const useOrdersData = () => useSelector((state: RootState) => state.orders.list)

export const useOrdersCursor = () => useSelector((state: RootState) => state.orders.cursor)

export const useIsOrders = () => useSelector((state: RootState) => state.orders.isOrders)
