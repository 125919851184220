import moment from 'moment'

export const getValueFromObject = (object: object, id: number | string) =>
  Object.values(object).find((item) => item.id === id)

export const getValueFromArray = (array: any[], id: number | string) => array.find((item) => item.id === id)

export const isNextDate = (date1: string, date2: string): boolean =>
  moment(date1).format('MM-DD-YYYY') !== moment(date2).format('MM-DD-YYYY')

//FIXME minus counter
export const displayCount = (count: number) =>
  count > 9 ? '9+'
  : count <= 0 ? ''
  : count

export const displayCountMax = (count: number) => (count > 99 ? '99+' : count)
