import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {SetProfileLike} from 'entities/activity'
import {updateLikeSearch} from 'entities/searchPeople'
import {names} from 'shared/lib'
import {ActiveLike, DeactivateLike, Like} from 'shared/ui/icons'
import styles from './styles.module.scss'

type LikeButtonProps = {
  wrapper?: string
  className?: string
  activeClassName?: string
  value?: boolean
  idUser?: string
  onClick?: () => void
  onChange?: (idUser: string, value: boolean) => void
}

export const LikeButton: React.FC<LikeButtonProps> = ({
  wrapper,
  className,
  value,
  idUser,
  activeClassName = '',
  onClick,
  onChange
}) => {
  const dispatch = useDispatch()

  const [isLike, setIsLike] = useState(value)
  const [isAnimationLike, setIsAnimationLike] = useState(false)
  const [isHoverLike, setIsHoverLike] = useState(false)
  const [isAfterAddAnimation, setIsAfterAddAnimation] = useState(false)

  const likeHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    if (isAnimationLike) return

    if (onClick) {
      onClick()
    }

    if (idUser) {
      SetProfileLike({isLike, idUser})
        .then(() => {
          dispatch(updateLikeSearch({idUser, value: Boolean(!isLike)}))

          if (!onChange) return

          onChange(idUser, Boolean(!isLike))
        })
        .catch(console.error)
    }

    if (!isLike) setIsAfterAddAnimation(true)
    setIsLike(!isLike)

    setIsAnimationLike(!isLike)
    setTimeout(() => setIsAnimationLike(false), Number(styles.animateTimeMs))
  }

  useEffect(() => {
    setIsLike(value)
  }, [value, idUser])

  return (
    <div className={names(wrapper, styles.wrapper)} onClick={likeHandler}>
      <div
        className={`
          ${styles.iconButton}
          ${className || ''}
          ${isLike ? styles.active : ''}
          ${isLike ? activeClassName : ''}
          ${isAnimationLike ? styles.animate : ''}
        `}
        onMouseEnter={() => setIsHoverLike(true)}
        onMouseLeave={() => {
          setIsAfterAddAnimation(false)
          setIsHoverLike(false)
        }}
      >
        <div>
          {isLike ?
            isHoverLike && !isAnimationLike && !isAfterAddAnimation ?
              <DeactivateLike />
            : <ActiveLike />
          : <Like />}
        </div>
      </div>
    </div>
  )
}
