import {FC, ImgHTMLAttributes, useState} from 'react'

type RetryImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  maxAttempts?: number
}

export const RetryImage: FC<RetryImageProps> = ({maxAttempts = 3, alt, ...rest}) => {
  const [attempts, setAttempts] = useState(0)

  const handleImageError = () => {
    if (attempts >= maxAttempts) return

    setAttempts((prevState) => prevState + 1)
  }

  return <img onError={handleImageError} {...rest} alt={alt} />
}
