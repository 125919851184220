import {Axios} from '@asocial/fe-utils'
import {AxiosResponse} from 'axios'
import {CursorType, ErrorResponsesType} from 'shared/types'
import {
  CorefyCardContextType,
  CryptoOptions,
  PackageType,
  PaymentMethodType,
  PaymentProviderType,
  TokenType
} from './types'

type GetPackagesResponse = {
  totalCount: number
  cursor: CursorType
  data: PackageType[]
}

type GetOptionsResponse = {
  provider: PaymentProviderType
  method: PaymentMethodType
  context: TokenType[] | null
}[]

export const GetPackages = (): Promise<AxiosResponse<GetPackagesResponse>> => Axios.get('payment/packages')

type GetTokensResponse = {
  totalCount: number
  cursor: CursorType
  data: TokenType[]
}

export const GetTokens = (): Promise<AxiosResponse<GetTokensResponse>> => Axios.get('payment/tokens')

export const GetOptions = (): Promise<AxiosResponse<GetOptionsResponse>> => Axios.get('payment/options')

export const PostOptions = (
  provider: PaymentProviderType,
  idPackage: number,
  method: PaymentMethodType
): Promise<AxiosResponse<CryptoOptions[]>> => Axios.post(`payment/${provider}/options`, {idPackage, method})

export const DeleteToken = (tokenId: string): Promise<AxiosResponse<null>> => Axios.delete(`payment/tokens/${tokenId}`)

export type PaymentOrderParams = {
  provider: PaymentProviderType
  method?: PaymentMethodType
  packageId: number
  idToken?: string
  autoCharge?: boolean
  width?: string
  height?: string
  currency?: string
  redirectPath?: string
  validationUrl?: string
  cardNumber?: string
  cardHolder?: string
  expireMonth?: number
  expireYear?: number
  zipCode?: string
  cvv?: string
  browser?: {
    tz: number
    screenWidth: number
    screenHeight: number
    colorDepth: number
    language: string
    javaEnabled: boolean
    userAgent: string
    windowWidth: number
    windowHeight: number
  }
  //requestId
  r?: string
}

type PaymentOrderContext = {redirectUrl?: string}

export type PaymentOrderResponse = {
  idOrder: string
  status: string
  context: PaymentOrderContext
}

export const PaymentOrder = ({
  provider,
  method,
  packageId,
  idToken,
  autoCharge = false,
  redirectPath = location.pathname,
  r,
  ...rest
}: PaymentOrderParams): Promise<AxiosResponse<PaymentOrderResponse, ErrorResponsesType>> =>
  Axios.post(`payment/${provider}/order`, {
    r,
    idPackage: packageId,
    autoCharge,
    method:
      method ?
        {
          type: method,
          id: idToken,
          successPath: redirectPath,
          cancelPath: redirectPath,
          failPath: redirectPath,
          ...rest
        }
      : null
  })

type PaymentOrderApplePayParams = {
  idPackage: number
  validationUrl: string
  r?: string
}

export const PaymentOrderApplePay = ({
  idPackage,
  validationUrl,
  r
}: PaymentOrderApplePayParams): Promise<AxiosResponse<PaymentOrderResponse, ErrorResponsesType>> =>
  Axios.post(`payment/${PaymentProviderType.COREFY}/order`, {
    r,
    idPackage,
    method: {
      type: PaymentMethodType.APPLE_PAY,
      validationUrl
    }
  })

type OrderProcessParams = {
  id: string
  token?: ApplePayJS.ApplePayPaymentToken
}

type OrderProcessResponse = {
  context: {success: boolean; threeDsContext?: {method: 'post' | 'put'; action: string; params: object}} | null
}

export const OrderProcess = ({id, token}: OrderProcessParams): Promise<AxiosResponse<OrderProcessResponse, null>> =>
  Axios.post(`payment/orders/${id}/process`, {token})

type GetAutoChargeResponse = {
  package: PackageType
  token: TokenType
} | null

export const GetAutoCharge = (): Promise<AxiosResponse<GetAutoChargeResponse>> => Axios.get('payment/auto-charge')

type SetAutoChargeParams = {
  idPackage: number
  idToken: string
}

export const SetAutoCharge = (params: SetAutoChargeParams): Promise<AxiosResponse<null>> =>
  Axios.post('payment/auto-charge', params)

export const DisableAutoCharge = (): Promise<AxiosResponse<null>> => Axios.delete('payment/auto-charge')

export const GetOrderContext = (idOrder: string): Promise<AxiosResponse<CorefyCardContextType>> =>
  Axios.get(`payment/orders/${idOrder}/context`)
