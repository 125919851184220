import {UserType} from 'entities/profile'
import {ImageType, StreamType} from '../messages'

export type RealGiftType = {
  id: number
  name: string
  description: string
  price: number
  url: string
  tags: {
    labels: string
  }
}

export type RealGiftCategoryType = {
  name: string
  realGifts: RealGiftType[]
}

export enum OrderStatusEnum {
  CREATED = 'created',
  PROCESSING = 'processing',
  DELIVERED = 'delivered',
  CANCELED = 'canceled'
}

export enum OrderConfirmationType {
  PHOTO = 'photo',
  VIDEO = 'video'
}

export type ConfirmationsType = {
  type: OrderConfirmationType
  content: ImageType | StreamType
}

export type OrderType = {
  id: string
  trustedUser: UserType
  price: number
  dateCreated: string
  status: OrderStatusEnum
  deliveryDate: string | null
  items: RealGiftType[]
  confirmations: ConfirmationsType[]
}
