import {AnchorHTMLAttributes, ButtonHTMLAttributes, FC, ReactNode} from 'react'
import {Link, LinkProps} from 'react-router-dom'
import styles from './styles.module.scss'

export type styleTypeButton =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'outlinedGray'
  | 'transparentPrimary'
  | 'primaryRed'

type BaseProps = {
  children: ReactNode
  className?: string
  styleType: styleTypeButton
  fullwidth?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  isWave?: boolean
}

export type BaseButtonType = BaseProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
    as?: 'button'
  }

type ButtonAsUnstyled = Omit<BaseButtonType, 'as' | 'styleType'> & {
  as: 'unstyled'
  styleType?: BaseProps['styleType']
}

export type ButtonAsLink = BaseProps &
  Omit<LinkProps, keyof BaseProps> & {
    as: 'link'
  }

type ButtonAsExternal = BaseProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseProps> & {
    as: 'externalLink'
  }

type ButtonProps = BaseButtonType | ButtonAsExternal | ButtonAsLink | ButtonAsUnstyled

export const Button: FC<ButtonProps> = (props) => {
  const allClassNames = `${props.styleType ? props.styleType : ''} ${props.className ? props.className : ''}`

  if (props.as === 'link') {
    // don't pass unnecessary props to component
    const {className, styleType, as, fullwidth, ...rest} = props
    return (
      <Link
        style={{width: fullwidth ? '100%' : undefined}}
        rel='nofollow'
        className={`${styles.customButton} ${styles[styleType]} ${className}`}
        {...rest}
      />
    )
  } else if (props.as === 'externalLink') {
    const {className, styleType, as, ...rest} = props
    return (
      <a
        className={allClassNames}
        // provide good + secure defaults while still allowing them to be overwritten
        target='_blank'
        rel='noopener noreferrer nofollow'
        {...rest}
      >
        {props.children}
      </a>
    )
  } else if (props.as === 'unstyled') {
    const {className, styleType, as, ...rest} = props
    return <button className={className} {...rest} />
  }
  const {className, styleType, isDisabled, fullwidth, as, isLoading, isWave, ...rest} = props

  return (
    <button
      style={{width: fullwidth ? '100%' : undefined}}
      className={`${styles.customButton} ${styles[styleType]} ${className || ''} ${isLoading ? styles.loading : ''} ${isDisabled ? styles.disabled : ''} ${isWave ? styles.wave : ''}`}
      disabled={isDisabled || isLoading}
      {...rest}
    />
  )
}
