import {useSnackbar, SnackbarContent, CustomContentProps, enqueueSnackbar} from 'notistack'
import {forwardRef, useCallback} from 'react'
import {Cross} from '../icons'
import styles from './styles.module.scss'

export const createSnackbar = ({title, text}: {title?: string; text: string}) => {
  enqueueSnackbar({
    message: (
      <>
        {title ?
          <div className={styles.title}>{title}</div>
        : null}
        <div>{text}</div>
      </>
    )
  })
}

export const DefaultSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(({id, message}, ref) => {
  const {closeSnackbar} = useSnackbar()
  const handleDismiss = useCallback(() => closeSnackbar(id), [id, closeSnackbar])

  return (
    <SnackbarContent ref={ref}>
      <div className={styles.root}>
        <div className={styles.message}>{message}</div>

        <div className={styles.close} onClick={handleDismiss}>
          <Cross />
        </div>
      </div>
    </SnackbarContent>
  )
})
