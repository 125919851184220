import {useEffect, useState} from 'react'
import {currentMirror} from './config'

export type textType = {
  main: {
    siteName: string
    supportMail: string
    canadaAddress: string
    estoniaAddress: string
    ukAddress: string
    usAddress: string
    gibraltarAddress: string
    facebook: string
    instagram: string
    youtube: string
    twitter: string
    medium: string
  }
}

export const text: textType = {
  main: {
    siteName: '',
    supportMail: '',
    canadaAddress: '',
    estoniaAddress: '',
    ukAddress: '',
    usAddress: '',
    gibraltarAddress: '',
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: '',
    medium: '',
  }
}

export function useTextLoad(): boolean {
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    (async () => {
      for (const key of Object.keys(text)) {
        text[key as keyof textType] = await import(`shared/mirrors/${currentMirror}/text/${key}`).then(
          (module) => module[key]
        )
      }
    })()

    setIsLoad(true)
  }, [])

  return isLoad
}
