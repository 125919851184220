import React, {HTMLProps} from 'react'
import {Cross} from 'shared/ui/cross'
import styles from './styles.module.scss'

type DrawerTitleProps = HTMLProps<HTMLDivElement> & {
  onCloseHandler: () => void
}

export const Title: React.FC<DrawerTitleProps> = ({onCloseHandler, children, className, ...rest}) => (
  <div className={`${styles.headerWrapper} ${className || ''}`} {...rest}>
    {children}
    <Cross onClose={onCloseHandler} />
  </div>
)
