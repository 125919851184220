import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'
import {CountryKeys} from 'shared/lib'
import {VERIFY_EMAIL_LAST_TIME_CLICK_LOCAL_STORAGE_KEY} from 'shared/mirrors/config'

export const IS_PROFILE_CHAT_OPEN_LS = 'isProfileChatOpen'
export const IS_CHAT_REQUESTS_HIDE = 'isChatRequestsHide'

type initialStateProps = {
  isTrialModalOpen: boolean
  isSignInModalOpen: boolean
  isSignUpModalOpen: boolean
  isProfileChatOpen: boolean
  isChatRequestsHide: boolean
  isNewPasswordModal: boolean
  isVerifyEmailModal: boolean
  isInactiveModalOpen: boolean
  postRegisterModalOpen: {
    uploadPhoto?: boolean
    interests?: boolean
  }
  isChangedEmailModal: boolean
  isSuccessSentEmailModal: boolean
  verifyEmailLastTimeClick: string
  isInterestsAsked?: boolean
  connectSocket: number
  turnstileSiteKey?: string
  isOnlineConnection: boolean
  initialCountry?: CountryKeys
  detectedCountry?: CountryKeys
  isSwitchBrowserModalOpen: boolean
  myContactsListScroll: number
  chatRequestsListScroll: number
}

export const initialStateAppModel: initialStateProps = {
  isTrialModalOpen: false,
  isSignInModalOpen: false,
  isSignUpModalOpen: false,
  isProfileChatOpen: true,
  isChatRequestsHide: false,
  isNewPasswordModal: false,
  isVerifyEmailModal: false,
  isInactiveModalOpen: false,
  postRegisterModalOpen: {
    uploadPhoto: false,
    interests: false
  },
  isChangedEmailModal: false,
  isSuccessSentEmailModal: false,
  verifyEmailLastTimeClick: '',
  isInterestsAsked: false,
  isSwitchBrowserModalOpen: false,
  connectSocket: 0,
  initialCountry: undefined,
  detectedCountry: undefined,
  turnstileSiteKey: undefined,
  isOnlineConnection: true,
  myContactsListScroll: 0,
  chatRequestsListScroll: 0
}

export const appModel = createSlice({
  name: 'app',
  initialState: initialStateAppModel,
  reducers: {
    setTrialModalOpen: (state, {payload}: PayloadAction<boolean>) => {
      state.isTrialModalOpen = payload
    },
    setSignInModalOpen: (state, {payload}: PayloadAction<boolean>) => {
      if (payload) state.isSignUpModalOpen = false
      state.isSignInModalOpen = payload
    },
    setSignUpModalOpen: (state, {payload}: PayloadAction<boolean>) => {
      if (payload) state.isSignInModalOpen = false
      state.isSignUpModalOpen = payload
    },
    setProfileChatOpen: (state, {payload}: PayloadAction<boolean>) => {
      localStorage.setItem(IS_PROFILE_CHAT_OPEN_LS, JSON.stringify({value: payload}))
      state.isProfileChatOpen = payload
    },
    setChatRequestsHide: (state, {payload}: PayloadAction<boolean>) => {
      localStorage.setItem(IS_CHAT_REQUESTS_HIDE, JSON.stringify({value: payload}))
      state.isChatRequestsHide = payload
    },
    setNewPasswordModalOpen: (state, {payload}: PayloadAction<boolean>) => {
      state.isNewPasswordModal = payload
    },
    setInactiveModalOpen: (state, {payload}: PayloadAction<boolean>) => {
      state.isInactiveModalOpen = payload
    },
    setVerifyEmailModalOpen: (state, {payload}: PayloadAction<boolean>) => {
      state.isVerifyEmailModal = payload
    },
    setPostRegisterModalOpen: (state, {payload}: PayloadAction<initialStateProps['postRegisterModalOpen']>) => {
      state.postRegisterModalOpen = {
        ...state.postRegisterModalOpen,
        ...payload
      }
    },
    setSuccessSentEmailModalOpen: (state, {payload}: PayloadAction<boolean>) => {
      state.isSuccessSentEmailModal = payload
    },
    setVerifyEmailLastTimeClick: (state, {payload}: PayloadAction<string>) => {
      state.verifyEmailLastTimeClick = payload
      localStorage.setItem(VERIFY_EMAIL_LAST_TIME_CLICK_LOCAL_STORAGE_KEY, JSON.stringify({value: payload}))
    },
    setChangedEmailModalOpen: (state, {payload}: PayloadAction<boolean>) => {
      state.isChangedEmailModal = payload
    },
    setSwitchBrowserModalOpen: (state, {payload}: PayloadAction<boolean>) => {
      state.isSwitchBrowserModalOpen = payload
    },
    changeConnectSocket: (state) => {
      state.connectSocket = Date.now()
    },
    setInitialCountry: (state, {payload}: PayloadAction<CountryKeys>) => {
      state.initialCountry = payload
    },
    setDetectedCountry: (state, {payload}: PayloadAction<CountryKeys>) => {
      state.detectedCountry = payload
    },
    setTurnstileSiteKey: (state, {payload}: PayloadAction<string>) => {
      state.turnstileSiteKey = payload
    },
    setOnlineConnection: (state, {payload}: PayloadAction<boolean>) => {
      state.isOnlineConnection = payload
    },
    setMyContactsListScroll: (state, {payload}: PayloadAction<number>) => {
      state.myContactsListScroll = payload
    },
    setChatRequestsListScroll: (state, {payload}: PayloadAction<number>) => {
      state.chatRequestsListScroll = payload
    }
  }
})

export const {
  setTrialModalOpen,
  setSignInModalOpen,
  setSignUpModalOpen,
  setProfileChatOpen,
  setChatRequestsHide,
  setNewPasswordModalOpen,
  setInactiveModalOpen,
  setVerifyEmailModalOpen,
  setPostRegisterModalOpen,
  setSuccessSentEmailModalOpen,
  setVerifyEmailLastTimeClick,
  setChangedEmailModalOpen,
  setSwitchBrowserModalOpen,
  changeConnectSocket,
  setInitialCountry,
  setDetectedCountry,
  setTurnstileSiteKey,
  setOnlineConnection,
  setMyContactsListScroll,
  setChatRequestsListScroll
} = appModel.actions

export const useTrialModal = () => useSelector((state: RootState) => state.app.isTrialModalOpen)

export const useSignInModal = () => useSelector((state: RootState) => state.app.isSignInModalOpen)

export const useSignUpModal = () => useSelector((state: RootState) => state.app.isSignUpModalOpen)

export const useProfileChat = () => useSelector((state: RootState) => state.app.isProfileChatOpen)

export const useChatRequestsHide = () => useSelector((state: RootState) => state.app.isChatRequestsHide)

export const useNewPasswordModal = () => useSelector((state: RootState) => state.app.isNewPasswordModal)

export const useVerifyEmailModal = () => useSelector((state: RootState) => state.app.isVerifyEmailModal)

export const useInactiveModal = () => useSelector((state: RootState) => state.app.isInactiveModalOpen)

export const usePostRegisterModalOpen = () => useSelector((state: RootState) => state.app.postRegisterModalOpen)

export const useChangedEmailModal = () => useSelector((state: RootState) => state.app.isChangedEmailModal)

export const useVerifyEmailLastTimeClick = () => useSelector((state: RootState) => state.app.verifyEmailLastTimeClick)

export const useSuccessSentEmailModal = () => useSelector((state: RootState) => state.app.isSuccessSentEmailModal)

export const useConnectSocket = () => useSelector((state: RootState) => state.app.connectSocket)

export const useSwitchBrowserModal = () => useSelector((state: RootState) => state.app.isSwitchBrowserModalOpen)

export const useTurnstileSiteKey = () => useSelector((state: RootState) => state.app.turnstileSiteKey)

export const useInitialCountry = () => useSelector((state: RootState) => state.app.initialCountry)

export const useDetectedCountry = () => useSelector((state: RootState) => state.app.detectedCountry)

export const useIsUSCountry = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.app.detectedCountry,
      (state: RootState) => state.app.initialCountry,
      (detectedCountry, initialCountry) => detectedCountry === 'US' || initialCountry === 'US'
    )
  )

export const useOnlineConnection = () => useSelector((state: RootState) => state.app.isOnlineConnection)

export const useMyContactsListScroll = () => useSelector((state: RootState) => state.app.myContactsListScroll)

export const useChatRequestsListScroll = () => useSelector((state: RootState) => state.app.chatRequestsListScroll)
