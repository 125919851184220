import {createSelector, createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'

type VideoPlayerProps = {
  iframe?: string
  poster?: string
}

const initialState: VideoPlayerProps = {}

export const videoPlayerModel = createSlice({
  name: 'videoPlayer',
  initialState,
  reducers: {
    setVideoPlayer: (state, {payload}: PayloadAction<VideoPlayerProps>) => {
      state.iframe = payload.iframe
      state.poster = payload.poster
    }
  }
})

export const {setVideoPlayer} = videoPlayerModel.actions

export const useVideoPlayerData = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.videoPlayer.iframe,
      (state: RootState) => state.videoPlayer.poster,
      (iframe, poster) => ({iframe, poster})
    )
  )

export const closeVideoPlayer = (dispatch: Dispatch) => dispatch(setVideoPlayer({}))
