import {AxiosResponse} from 'axios'
import {CursorType, SearchUser, TotalCountType} from 'shared/types'

export type ActivityUser = {
  id: string
  name: string
  avatarUrl: string | null
  isOnline: boolean
  age: number
  isConfirmed: boolean
  isPopular: boolean
  connection: {
    likeTime: string | null
    favoriteTime: string | null
  } | null
}

export enum NeutralNotificationTypes {
  ONLINE = 'online',
  PHOTO_UPLOADED = 'photo_upload'
}

export enum NotificationTypes {
  LIKE = 'like',
  FAVORITE = 'favorite',
  MATCH = 'match',
  VISIT = 'visit',
  POSSIBLE_MATCH = 'possible_match'
}

export type NotificationKeysTypes =
  | 'all'
  | 'allMatch'
  | NotificationTypes.VISIT
  | NotificationTypes.LIKE
  | NotificationTypes.FAVORITE

export type NotificationType = {
  id: string
  from: ActivityUser
  type: NotificationTypes | NeutralNotificationTypes
  dateCreated: string
}

export type GetNotificationsResponse = AxiosResponse<{
  totalCount: TotalCountType
  cursor: CursorType
  data: NotificationType[]
}>

export type GetFavoritesResponse = {
  cursor: CursorType
  data: SearchUser[]
}
