import moment from 'moment/moment'
import {TrackingEventType} from './types'

export const events: TrackingEventType[] = []

export const trackEvent = ({
  event,
  context = null,
  date = Math.floor(moment().utc().valueOf() / 1000)
}: TrackingEventType) => events.push({event, context, date})
