import {Axios} from '@asocial/fe-utils'
import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {UserType} from 'entities/profile'
import {CursorType, ErrorResponsesType} from 'shared/types'
import {GetChatRequestsRequest} from '../api'
import {ChatUserType, GetChatRequestsResponse} from '../types'
import {ContentPurchased, MessageType} from './messages'

export enum DialogType {
  ACTIVE = 'active',
  REQUEST = 'request'
}

export type DialogItemType = {
  type: DialogType
  interlocutor: ChatUserType
  unreadCount: number
  lastMessage: MessageType
  isPinned: boolean
  idLastReadMessage: string | null
  dateCreated: string
}

export type FiltersDialogs = 'all' | DialogType

export type ChatCountersType = {
  [DialogType.ACTIVE]: number
  [DialogType.REQUEST]: number
}

type DialogsProps = {
  filter: FiltersDialogs
  dialogsList: DialogItemType[]
  dialogsCursor: CursorType
  dialogsTotalCount: number
  search: string
  searchList: DialogItemType[]
  searchCursor: CursorType
  chatRequestsList: DialogItemType[]
  chatRequestsCursor: CursorType
  chatRequestsTotalCount: number
  chatRequestsNewList: string[]
  openDialog: DialogItemType | null
  mockDialog: UserType | null
  counters: ChatCountersType
  unreadMessages: number
}

export const initialDialogsModel: DialogsProps = {
  filter: 'all',
  dialogsList: [],
  dialogsCursor: '',
  dialogsTotalCount: 0,
  search: '',
  searchList: [],
  searchCursor: '',
  chatRequestsList: [],
  chatRequestsCursor: '',
  chatRequestsTotalCount: 0,
  chatRequestsNewList: [],
  openDialog: null,
  mockDialog: null,
  counters: {
    [DialogType.ACTIVE]: 0,
    [DialogType.REQUEST]: 0
  },
  unreadMessages: 0
}

export const dialogsModel = createSlice({
  name: 'dialogs',
  initialState: initialDialogsModel,
  reducers: {
    addDialogList: (state, {payload}: PayloadAction<{cursor: string; data: DialogItemType[]; totalCount: number}>) => {
      if (state.dialogsCursor === payload.cursor) return

      state.dialogsCursor = payload.cursor
      state.dialogsList.push(...payload.data)
      state.dialogsTotalCount = payload.totalCount
    },
    addDialogSearchList: (
      state,
      {payload}: PayloadAction<{cursor: string; data: DialogItemType[]; totalCount: number}>
    ) => {
      state.searchCursor = payload.cursor
      state.searchList.push(...payload.data)
    },
    orderDialogList: (state, {payload: dialog}: PayloadAction<DialogItemType>) => {
      if (dialog.unreadCount === 1) state.counters[dialog.type]++
      if (dialog.unreadCount > 0) state.unreadMessages++

      // totalCount update for new dialogue
      if (dialog.dateCreated === dialog.lastMessage.dateCreated) {
        state.dialogsTotalCount++
      }

      if (
        state.openDialog?.interlocutor?.id === dialog.interlocutor.id ||
        state.mockDialog?.id === dialog.interlocutor.id
      ) {
        state.openDialog = dialog
      }

      // Delete mockDialog
      if (state.mockDialog?.id === dialog.interlocutor.id) {
        state.mockDialog = null
      }

      // Update dialog list
      if (state.dialogsCursor !== '') {
        state.dialogsList = state.dialogsList.filter((item) => item.interlocutor.id !== dialog.interlocutor.id)

        // Если диалог запинен, то закидываем его в начало
        if (dialog.isPinned) {
          state.dialogsList.unshift(dialog)
        } else {
          // Иначе закидываем в начало незапининых диалогов
          const index = state.dialogsList.findIndex((item) => !item.isPinned)
          index < 0 ? state.dialogsList.push(dialog) : state.dialogsList.splice(index, 0, dialog)
        }
      }

      // Update search list
      if (state.searchCursor !== '') {
        if (state.search && !state.searchList.find((item) => item.interlocutor.id === dialog.interlocutor.id)) return

        state.searchList = state.searchList.filter((item) => item.interlocutor.id !== dialog.interlocutor.id)

        // Если диалог запинен, то закидываем его в начало
        if (dialog.isPinned) {
          state.searchList.unshift(dialog)
        } else {
          // Иначе закидываем в начало незапининых диалогов
          const searchIndex = state.searchList.findIndex((item) => !item.isPinned)
          searchIndex < 0 ? state.searchList.push(dialog) : state.searchList.splice(searchIndex, 0, dialog)
        }
      }
    },
    setOpenDialog: (state, {payload}: PayloadAction<DialogItemType | null>) => {
      state.openDialog = payload
    },
    pinUnpinDialog: (state, {payload}: PayloadAction<{userId: string; isPinned: boolean}>) => {
      const {isPinned, userId} = payload

      if (state.openDialog) state.openDialog.isPinned = isPinned

      const dialog = state.dialogsList.find(
        ({lastMessage}) => lastMessage.from.id === userId || lastMessage.to.id === userId
      )
      if (dialog) {
        dialog.isPinned = isPinned

        state.dialogsList = state.dialogsList.filter((item) => item.interlocutor.id !== dialog.interlocutor.id)

        if (isPinned) {
          state.dialogsList.unshift(dialog)
        } else {
          const index = state.dialogsList.findIndex((item) => !item.isPinned)
          index < 0 ? state.dialogsList.push(dialog) : state.dialogsList.splice(index, 0, dialog)
        }
      }

      const searchDialog = state.searchList.find(
        ({lastMessage}) => lastMessage.from.id === userId || lastMessage.to.id === userId
      )
      if (searchDialog) {
        searchDialog.isPinned = isPinned

        state.searchList = state.searchList.filter((item) => item.interlocutor.id !== searchDialog.interlocutor.id)

        if (isPinned) {
          state.searchList.unshift(searchDialog)
        } else {
          const index = state.searchList.findIndex((item) => !item.isPinned)
          index < 0 ? state.searchList.push(searchDialog) : state.searchList.splice(index, 0, searchDialog)
        }
      }
    },
    setDialogSearch: (state, {payload: search}: PayloadAction<string>) => {
      if (state.search === search) return

      state.searchCursor = ''
      state.searchList = []
      state.search = search
    },
    readDialog: (
      state,
      {
        payload
      }: PayloadAction<{
        type: DialogType
        idUser: string
        idMessage: string
        lastUnreadCount: number
        unreadCount: number
      }>
    ) => {
      const {idMessage, idUser, lastUnreadCount, unreadCount, type} = payload

      // update counters
      if (unreadCount === 0) state.counters[type]--
      if (state.counters[type] < 0) state.counters[type] = 0

      // update unreadMessages
      state.unreadMessages -= lastUnreadCount - unreadCount
      if (state.unreadMessages < 0) state.unreadMessages = 0

      if (
        state.openDialog &&
        (state.openDialog.idLastReadMessage == null || idMessage > state.openDialog.idLastReadMessage)
      ) {
        state.openDialog.idLastReadMessage = idMessage
        state.openDialog.unreadCount = unreadCount
      }

      const dialog = (type === DialogType.ACTIVE ? state.dialogsList : state.chatRequestsList).find(
        ({interlocutor}) => interlocutor.id === idUser
      )
      if (dialog && (dialog.idLastReadMessage == null || idMessage > dialog.idLastReadMessage)) {
        dialog.idLastReadMessage = idMessage
        dialog.unreadCount = unreadCount
      }

      const searchDialog = state.searchList.find(({interlocutor}) => interlocutor.id === idUser)
      if (searchDialog && (searchDialog.idLastReadMessage == null || idMessage > searchDialog.idLastReadMessage)) {
        searchDialog.idLastReadMessage = idMessage
        searchDialog.unreadCount = unreadCount
      }
    },
    setMockDialog: (state, {payload}: PayloadAction<UserType | null>) => {
      state.mockDialog = payload
    },
    clearDialogsList: (state) => {
      state.dialogsCursor = ''
      state.dialogsList = []
      state.dialogsTotalCount = 0

      state.searchCursor = ''
      state.searchList = []
    },
    setDialogFilter: (state, {payload}: PayloadAction<FiltersDialogs>) => {
      state.filter = payload

      if (state.search) {
        state.searchCursor = ''
        state.searchList = []
      }
    },
    addChatRequestsData: (state, {payload}: PayloadAction<GetChatRequestsResponse>) => {
      if (state.chatRequestsCursor === payload.cursor) return

      state.chatRequestsTotalCount = payload.totalCount
      state.chatRequestsCursor = payload.cursor
      state.chatRequestsList.push(...payload.data)
    },
    setChatRequestsData: (state, {payload}: PayloadAction<GetChatRequestsResponse>) => {
      state.chatRequestsTotalCount = payload.totalCount
      state.chatRequestsCursor = payload.cursor
      state.chatRequestsList = payload.data
    },
    deleteChatRequest: (state, {payload: idUser}: PayloadAction<string>) => {
      if (state.chatRequestsList.find((item) => item.interlocutor.id === idUser)) {
        state.chatRequestsList = state.chatRequestsList.filter((item) => item.interlocutor.id !== idUser)
        state.chatRequestsTotalCount--
        state.dialogsTotalCount++
      }
    },
    addChatRequestsUp: (state, {payload: dialog}: PayloadAction<DialogItemType>) => {
      state.counters[dialog.type]++
      state.unreadMessages++

      if (state.chatRequestsCursor !== '') {
        if (state.chatRequestsList.find((item) => item.interlocutor.id === dialog.interlocutor.id)) {
          state.chatRequestsList = state.chatRequestsList.filter(
            (item) => item.interlocutor.id !== dialog.interlocutor.id
          )
          state.chatRequestsTotalCount--
        }

        state.chatRequestsList.unshift(dialog)
        state.chatRequestsTotalCount++
        state.chatRequestsNewList.push(dialog.interlocutor.id)
      }

      if (state.searchCursor !== '') {
        if (state.search && !state.searchList.find((item) => item.interlocutor.id === dialog.interlocutor.id)) return

        state.searchList = state.searchList.filter((item) => item.interlocutor.id !== dialog.interlocutor.id)

        const searchIndex = state.searchList.findIndex((item) => !item.isPinned)
        searchIndex < 0 ? state.searchList.push(dialog) : state.searchList.splice(searchIndex, 0, dialog)
      }
    },
    clearNewChatRequest: (state) => {
      state.chatRequestsNewList = []
    },
    setChatCounters: (state, {payload}: PayloadAction<ChatCountersType>) => {
      state.counters = payload
    },
    setChatUnreadMessages: (state, {payload}: PayloadAction<number>) => {
      state.unreadMessages = payload
    },
    setPurchaseMedia: (state, {payload}: PayloadAction<string>) => {
      for (const {lastMessage} of state.dialogsList) {
        if (lastMessage.id === payload) {
          (lastMessage.content as ContentPurchased).purchased = true
          break
        }
      }

      for (const {lastMessage} of state.searchList) {
        if (lastMessage.id === payload) {
          (lastMessage.content as ContentPurchased).purchased = true
          break
        }
      }

      for (const {lastMessage} of state.chatRequestsList) {
        if (lastMessage.id === payload) {
          (lastMessage.content as ContentPurchased).purchased = true
          break
        }
      }
    }
  }
})

export const {
  addDialogList,
  orderDialogList,
  setOpenDialog,
  setDialogSearch,
  readDialog,
  addDialogSearchList,
  setMockDialog,
  clearDialogsList,
  setDialogFilter,
  addChatRequestsData,
  setChatRequestsData,
  addChatRequestsUp,
  deleteChatRequest,
  clearNewChatRequest,
  setChatCounters,
  setChatUnreadMessages,
  setPurchaseMedia
} = dialogsModel.actions

export type GetDialogsResponse = AxiosResponse<{
  cursor: string
  data: DialogItemType[]
  totalCount: number
}>

export const GetDialogs = (dispatch: Dispatch) =>
  useQuery<GetDialogsResponse, ErrorResponsesType, GetDialogsResponse, [string, CursorType]>({
    queryKey: ['chatDialogs', useSelector((state: RootState) => state.dialogs.dialogsCursor)],
    queryFn: ({queryKey: [_, cursor]}) =>
      Axios.get(`chat/dialogs/${DialogType.ACTIVE}`, {params: {cursor: cursor || null}}).then(({data}) => {
        dispatch(addDialogList(data))
        return data
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })

export const GetDialogsSearch = (dispatch: Dispatch, filter?: FiltersDialogs) =>
  useQuery<GetDialogsResponse, ErrorResponsesType, GetDialogsResponse, [string, CursorType, string, FiltersDialogs]>({
    queryKey: [
      'chatDialogsSearch',
      useSelector((state: RootState) => state.dialogs.searchCursor),
      useSelector((state: RootState) => state.dialogs.search),
      useSelector((state: RootState) => state.dialogs.filter)
    ],
    queryFn: ({queryKey: [_, searchCursor, search, filterStore], signal}) =>
      Axios.get((filter || filterStore) === 'all' ? 'chat/dialogs' : `chat/dialogs/${filter || filterStore}`, {
        params: {cursor: searchCursor || null, search: search || null},
        signal
      }).then(({data}) => {
        dispatch(addDialogSearchList(data))
        return data
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })

export const GetDialog = (userId: string, dispatch: Dispatch) =>
  useQuery<AxiosResponse<DialogItemType | null>, ErrorResponsesType>({
    queryKey: ['chatDialog'],
    queryFn: () =>
      Axios.get(`chat/dialogs/${userId}`).then(({data}) => {
        data && dispatch(setOpenDialog(data))
        return data
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })

export const PinUnpinDialog = (userId: string, pin: boolean, dispatch: Dispatch) =>
  useQuery<AxiosResponse<DialogItemType>, ErrorResponsesType>({
    queryKey: ['pinDialog'],
    queryFn: () =>
      Axios.patch(`chat/dialogs/${userId}/${pin ? 'pin' : 'unpin'}`).then(({data}) => {
        dispatch(dialogsModel.actions.pinUnpinDialog({userId, isPinned: pin}))
        return data
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })

export const GetChatRequests = (dispatch: Dispatch) =>
  useQuery<
    AxiosResponse<GetChatRequestsResponse>,
    ErrorResponsesType,
    AxiosResponse<GetChatRequestsResponse>,
    [string, CursorType]
  >({
    queryKey: ['getChatRequests', useSelector((state: RootState) => state.dialogs.chatRequestsCursor)],
    queryFn: ({queryKey: [_, cursor]}) =>
      GetChatRequestsRequest(cursor).then((data) => {
        dispatch(addChatRequestsData(data.data))
        return data
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false
  })

export const useChatRequestsList = () => useSelector((state: RootState) => state.dialogs.chatRequestsList)

export const useChatRequestsNew = () => useSelector((state: RootState) => state.dialogs.chatRequestsNewList)

export const useChatRequestsCursor = () => useSelector((state: RootState) => state.dialogs.chatRequestsCursor)

export const useChatRequestsTotalCount = () =>
  useSelector((state: RootState) => state.dialogs.chatRequestsTotalCount || 0)

export const useDialogsList = () => useSelector((state: RootState) => state.dialogs.dialogsList)

export const useDialogsSearchList = () => useSelector((state: RootState) => state.dialogs.searchList)

export const useOpenDialog = () => useSelector((state: RootState) => state.dialogs.openDialog)

export const useDialogsCursor = () => useSelector((state: RootState) => state.dialogs.dialogsCursor)

export const useDialogsSearchCursor = () => useSelector((state: RootState) => state.dialogs.searchCursor)

export const useDialogsSearch = () => useSelector((state: RootState) => state.dialogs.search)

export const useDialogsUnreadCount = () => useSelector((state: RootState) => state.dialogs.unreadMessages)

export const useDialogsCounters = () => useSelector((state: RootState) => state.dialogs.counters)

export const useMockDialog = () => useSelector((state: RootState) => state.dialogs.mockDialog)

export const useDialogsTotalCount = () => useSelector((state: RootState) => state.dialogs.dialogsTotalCount)

export const useDialogsFilter = () => useSelector((state: RootState) => state.dialogs.filter)
