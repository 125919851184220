type MirrorsKeys = 'gochatty' | 'mirrortest'

export const Mirrors: Record<MirrorsKeys, string> = {
  gochatty: 'gochatty.asocial-team.com',
  mirrortest: 'mirrortest.asocial-team.com'
}

const LOCAL_MIRROR = Mirrors.gochatty
const DEFAULT_MIRROR = 'gochatty'

const getKeyFromEnumByValue = (enumValue: string): string => {
  for (const key of Object.keys(Mirrors)) {
    if (Mirrors[key as keyof typeof Mirrors] === enumValue) return key
  }

  return DEFAULT_MIRROR
}

export const currentMirror = getKeyFromEnumByValue(
  process.env.NODE_ENV === 'development' ? LOCAL_MIRROR : window.location.hostname
) as MirrorsKeys

export const MARKETING_COOKIE_KEY = 'mg'
export const VERIFY_EMAIL_LAST_TIME_CLICK_LOCAL_STORAGE_KEY = 'verifyEmailLastTimeClick'
export const AUTH_TIME_SESSION_STORAGE_KEY = 'authTime'
export const GET_COINS_SESSION_STORAGE_KEY = 'getCoins'
export const PREVIOUS_PAGE_SESSION_STORAGE_KEY = 'previousPage'
export const PUSH_MODAL_LAST_TIME_LOCAL_STORAGE_KEY = 'pushModal'

// Пример проверок в модулях
// if (Mirrors[currentMirror] === Mirrors.gochatty) {
//   console.log('logic')
// }
//
// if (Mirrors[currentMirror] === Mirrors.testMirror) {
//   console.log('logic')
// }
