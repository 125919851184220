import {Axios} from '@asocial/fe-utils'
import {createSelector, createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {ErrorResponsesType} from 'shared/types'

type StickerType = {
  id: number
  type: null | 'greeting'
  data: {
    url: string
  }
}

export type StickerPackType = {
  price: number
  name: string
  stickers: StickerType[]
}

export type StickersModelType = {
  data: StickerPackType[]
}

const initialState: StickersModelType = {
  data: []
}

export const stickersModel = createSlice({
  name: 'stickersModel',
  initialState,
  reducers: {
    setStickers: (state, {payload}: PayloadAction<StickerPackType[]>) => {
      state.data = payload
    }
  }
})

export const GetStickers = (dispatch: Dispatch) =>
  useQuery<AxiosResponse<{data: StickerPackType[]}>, ErrorResponsesType>({
    queryKey: ['getStickers'],
    queryFn: () =>
      Axios.get(`chat/stickers`).then(({data: response}) => {
        dispatch(stickersModel.actions.setStickers(response.data))
        return response
      }),
    refetchOnWindowFocus: false,
    retry: false
  })

export const useStickersData = () => useSelector((state: RootState) => state.stickers.data)

export const useStickersGreeting = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.stickers.data,
      (data) =>
        data
          .reduce((prev, curr) => [...prev, ...curr.stickers], [] as StickerType[])
          .filter((item) => item.type === 'greeting')
    )
  )
