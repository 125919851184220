import {CSSProperties, FC, MouseEventHandler, ReactElement} from 'react'
import {Gender} from 'shared/types'
import {GirlDisabled, GirlEnabled, ManDisabled, ManEnabled} from 'shared/ui/icons'
import styles from './styles.module.scss'

type SexIconProps = {
  onClick: MouseEventHandler<HTMLDivElement>
  style?: CSSProperties
  isActive: boolean
  gender: Gender
}

export const SexIcon: FC<SexIconProps> = ({onClick, isActive, gender}) => {
  const icon: ReactElement =
    gender === Gender.MALE ?
      isActive ? <ManEnabled />
      : <ManDisabled />
    : isActive ? <GirlEnabled />
    : <GirlDisabled />

  return (
    <div onClick={onClick} className={`${styles.sexIcon} ${isActive ? styles[`active__${gender}`] : ''}`}>
      <div className={styles.icon}>{icon}</div>
    </div>
  )
}
