import {Axios} from '@asocial/fe-utils'
import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {useSelector} from 'react-redux'
import {mediaVariants} from 'shared/lib'
import {sentryTrackError} from 'shared/lib/sentry'
import {CursorType, ErrorResponsesType} from 'shared/types'

export const TOPICS_LIMIT = 20

export type Topic = {
  age: number
  city: string
  country: string
  gender: string
  idUser: string
  isConfirmed: boolean
  isFavorite: boolean
  isLike: boolean
  isOnline: boolean
  isPopular: boolean
  name: string
  photoUrl: string
}

export type Tag = {
  name: string
  id: number
}

export type TagsList = {
  name: string
  tags: Tag[]
}

export type TagsData = {
  totalCount: number
  cursor: null
  data: TagsList[]
}

export type TopicsModelProps = {
  cursor: CursorType
  topicsList: Topic[]
  tagsList: TagsList[]
  isOpenTopicsModal: boolean
  selectTags: number[]
  currentIndex: number
  isPreviouslyMountedTopics: boolean
}

export const initialStateTopicsModel: TopicsModelProps = {
  cursor: '',
  topicsList: [],
  tagsList: [],
  isOpenTopicsModal: false,
  selectTags: [],
  currentIndex: 3,
  isPreviouslyMountedTopics: false
}

export const topicsModel = createSlice({
  name: 'topics',
  initialState: initialStateTopicsModel,
  reducers: {
    setTopicsList: (state, {payload}: PayloadAction<{cursor: CursorType; data: Topic[]}>) => {
      for (const {photoUrl} of payload.data) {
        const img = new Image()
        img.src = `${photoUrl}${mediaVariants.originalS}`

        img.onerror = () => {
          console.error(`Failed to load image: ${photoUrl}`)
        }
      }
      state.cursor = payload.cursor
      state.topicsList.push(...payload.data)
    },
    setTopicTagsList: (state, {payload}: PayloadAction<TagsList[]>) => {
      state.tagsList = payload
    },
    setOpenTopicsModal: (state, {payload}: PayloadAction<boolean>) => {
      state.isOpenTopicsModal = payload
    },
    setSelectTags: (state, {payload}: PayloadAction<number[]>) => {
      state.cursor = ''
      state.topicsList = []
      state.isOpenTopicsModal = false
      state.selectTags = payload
    },
    setTopicsLikes(state, {payload}: PayloadAction<{idUser: string; value: boolean}>) {
      state.topicsList = state.topicsList.map((topic) => {
        if (topic.idUser === payload.idUser) {
          topic.isLike = payload.value
        }

        return topic
      })
    },
    setTopicsFavorites(state, {payload}: PayloadAction<{idUser: string; value: boolean}>) {
      state.topicsList = state.topicsList.map((topic) => {
        if (topic.idUser === payload.idUser) {
          topic.isFavorite = payload.value
        }

        return topic
      })
    },
    setTopicsCurrentIndex: (state, {payload}: PayloadAction<number>) => {
      state.currentIndex = payload
    },
    setIsPreviouslyMountedTopics: (state, {payload}: PayloadAction<boolean>) => {
      state.isPreviouslyMountedTopics = payload
    }
  }
})

export const {
  setTopicsList,
  setTopicTagsList,
  setOpenTopicsModal,
  setSelectTags,
  setTopicsLikes,
  setTopicsFavorites,
  setTopicsCurrentIndex,
  setIsPreviouslyMountedTopics
} = topicsModel.actions

export const GetTagsList = (dispatch: Dispatch) =>
  useQuery<TagsData, ErrorResponsesType>({
    queryKey: ['tagsList'],
    queryFn: () =>
      Axios.get('tags')
        .then(({data}) => {
          dispatch(setTopicTagsList(data.data))
          return data
        })
        .catch((error) => {
          sentryTrackError(`Tags list fetching error: ${JSON.stringify(error)}`)
          throw error
        }),
    refetchOnWindowFocus: false
  })

export const GetTopicsList = (dispatch: Dispatch) =>
  useQuery<TagsData, ErrorResponsesType, TagsData, [CursorType, number[], string]>({
    queryKey: [
      useSelector((state: RootState) => state.topics.cursor),
      useSelector((state: RootState) => state.topics.selectTags),
      'topicsList'
    ],
    queryFn: ({queryKey: [cursor, selectTags], signal}) =>
      Axios.get('topics', {
        signal,
        params: {
          limit: TOPICS_LIMIT,
          tags: selectTags.length ? selectTags.join(',') : null,
          cursor: cursor || null
        }
      })
        .then(({data}) => {
          dispatch(setTopicsList(data))
          return data
        })
        .catch((error) => {
          sentryTrackError(`Topics list fetching error: ${JSON.stringify(error)}`)
          throw error
        }),
    refetchOnWindowFocus: false,
    enabled: false
  })

export const useTopicTagsList = () => useSelector((state: RootState) => state.topics.tagsList)

export const useIsOpenTopicsModal = () => useSelector((state: RootState) => state.topics.isOpenTopicsModal)

export const useTopicsList = () => useSelector((state: RootState) => state.topics.topicsList)

export const useSelectTags = () => useSelector((state: RootState) => state.topics.selectTags)

export const useTopicsCursor = () => useSelector((state: RootState) => state.topics.cursor)

export const useTopicsCurrentIndex = () => useSelector((state: RootState) => state.topics.currentIndex)

export const useIsPreviouslyMountedTopics = () =>
  useSelector((state: RootState) => state.topics.isPreviouslyMountedTopics)
