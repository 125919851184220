const squareKey = 'sq'
const rectangleKey = 'rc'
//TODO вынести в fe-utils
export const mediaVariants = {
  square16: `/${squareKey}16`,
  square32: `/${squareKey}32`,
  square32blur: `/${squareKey}32b`,
  square64: `/${squareKey}64`,
  square64blur: `/${squareKey}64b`,
  square128: `/${squareKey}128`,
  square128blur: `/${squareKey}128b`,
  square256: `/${squareKey}256`,
  square256blur: `/${squareKey}256b`,
  square512: `/${squareKey}512`,
  square512blur: `/${squareKey}512b`,
  rectangle16: `/${rectangleKey}16`,
  rectangle32: `/${rectangleKey}32`,
  rectangle64: `/${rectangleKey}64`,
  rectangle128: `/${rectangleKey}128`,
  rectangle256: `/${rectangleKey}256`,
  rectangle512: `/${rectangleKey}512`,
  banner: '/banner',
  originalS: '/900p',
  originalM: '/1080p',
  originalL: '/1440p',
  originalLBlur: '/1440pb',
  originalXL: '/2160p',
  videoAvatar: '?height=600&fps=15'
}

export const preloadImage = (src: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = resolve
    image.onerror = reject
    image.src = src
  })

export const preloadImages = (images: string[]) => Promise.allSettled(images.map(preloadImage))
