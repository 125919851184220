import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'
import {Video} from 'shared/types'

export type DefaultVideoType = {
  iframeUrl: string
  jpgUrl: string
  gifUrl: string
  cfId: string
}

export type IntroVideoState = {
  purchased: boolean
  video: Video | DefaultVideoType
}

const defaultVideo: DefaultVideoType = {
  iframeUrl: '',
  jpgUrl: '',
  gifUrl: '',
  cfId: ''
}

const initialIntroVideoState: IntroVideoState = {
  purchased: false,
  video: defaultVideo
}

export const introVideoModel = createSlice({
  name: 'introVideo',
  initialState: initialIntroVideoState,
  reducers: {
    setIntroVideo: (state, action: PayloadAction<IntroVideoState>) => {
      state.purchased = action.payload.purchased
      state.video = action.payload.video
    },
    purchaseIntroVideo: (state) => {
      state.purchased = true
    }
  }
})

export const {setIntroVideo, purchaseIntroVideo} = introVideoModel.actions

export const useIntroVideo = () => useSelector((state: RootState) => state.introVideo)
